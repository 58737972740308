import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "./userService";

const initialState = {
    users: [],
    profile: null,
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message:""
}

export const allUsers = createAsyncThunk("users/all", async(user, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await userService.allUsers(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const changeUserRole = createAsyncThunk("user/change-role", async({userId, userType}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await userService.changeUserRole({userId, userType}, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const fetchUserProfile = createAsyncThunk("user/profiles", async({userId}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await userService.getProfiles({userId}, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const changeUserActivity = createAsyncThunk("user/change-activate", async({userId, activate}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await userService.changeUserActivity({userId, activate}, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})



export const userSlice = createSlice({
    name:"users",
    initialState,
    reducers:{
        reset: (state) => initialState
    },
    extraReducers:(builder) => {
        builder
        .addCase(allUsers.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(allUsers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.users = action.payload
        })
        .addCase(allUsers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(changeUserRole.pending, (state) => {
            state.isLoading = true
        }) 
        .addCase(changeUserRole.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            const updatedUser = action.payload;

            // Find the index of the user with the matching _id in the state.users array
            const userIndex = state.users.findIndex(user => user._id === updatedUser._id);
        
            // If the user is found, update the user in the array, else add it to the end
            if (userIndex !== -1) {
                state.users[userIndex] = updatedUser;
            } else {
                state.users.push(updatedUser);
            }
        })
        .addCase(changeUserRole.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false 
            state.message = action.payload
        })
        .addCase(changeUserActivity.pending, (state) => {
            state.isLoading = true
        }) 
        .addCase(changeUserActivity.fulfilled, (state,action) => {
            state.isLoading = false
            state.isSuccess = true
            const updatedUser = action.payload;

            // Find the index of the user with the matching _id in the state.users array
            const userIndex = state.users.findIndex(user => user._id === updatedUser._id);
        
            // If the user is found, update the user in the array, else add it to the end
            if (userIndex !== -1) {
                state.users[userIndex] = updatedUser;
            } else {
                state.users.push(updatedUser);
            }
        })
        .addCase(changeUserActivity.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false 
            state.message = action.payload
        })

        builder.addCase(fetchUserProfile.pending, (state) => {
            state.isLoading = true;
            state.message = null;
          });
      
          // Handle the fetchUserProfile.fulfilled action
          builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.profile = action.payload;
          });
      
          // Handle the fetchUserProfile.rejected action
          builder.addCase(fetchUserProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.message = action.error.message;
          });
        
    }
})

export const {reset} = userSlice.actions
export default userSlice.reducer