import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { changeUserComplaintStatus, getAllComplaint, reset } from '../app/reducers/complaintSlice';
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2';
const MySwal = withReactContent(Swal);

const StateComplaintModal = ({show, handleClose, complaint,  isSuccess}) => {
  const dispatch = useDispatch()
  const [editedComplaint, setEditedComplaint] = useState({ ...complaint, 
    status: complaint.status || '',  // Assuming status is a string
    statusInfo: complaint.statusInfo || '',  // Assuming statusInfo is a string
  
  });

  const handleInputChange = (e) => {
    setEditedComplaint({
      ...editedComplaint,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(changeUserComplaintStatus({ id: complaint._id , editedComplaint}));
    if (isSuccess) {
      MySwal.fire({
        icon: 'success',
        title: `Ticket updated`,
      });
    }
    
    dispatch(getAllComplaint())
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <form onSubmit={handleSubmit}>
        <Modal.Header className='bg-crimson text-white'>
            <Modal.Title>
                TICKET - #{complaint._id}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <div className="mb-3">
                    <label htmlFor="status" className="form-label text-uppercase">
                    Status
                    </label>
                    <select
                    className="form-control"
                    id="status"
                    name="status"
                    value={editedComplaint.status}  // Add value attribute
                    onChange={handleInputChange}
                    >
                    <option value="">Choose ...</option>
                    <option value="open">open</option>
                    <option value="processing">Processing</option>
                    <option value="closed">Closed</option>
                    </select>
            </div>
            <div className='mb-3'>
                <label htmlFor="status" className="form-label text-uppercase">
                    Ticket - Response
                </label>
                <textarea className='form-control' name='statusInfo' value={editedComplaint.statusInfo} onChange={handleInputChange} required>

                </textarea>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-muted-blue'>
                Respond - Ticket
            </button>
        </Modal.Footer>
        </form>
    </Modal>
  )
}

export default StateComplaintModal