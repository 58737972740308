import React, { useState } from 'react';
import OrderModal from './OrderModal';
import OrderStatusModal from './OrderStatusModal';
import { fetchProductById } from '../app/reducers/productSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faReceipt, faUser } from '@fortawesome/free-solid-svg-icons';
import UserOrderModal from './UserOrderModal';


const OrderListing = ({ order, is_user,isSuccess }) => {
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showUserOrderModal, setShowUserOrderModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const { products, loading } = useSelector((state) => state.products);

  const dispatch = useDispatch();

  const handleOrderStatusClick = () => {
    setShowOrderModal(true);
  };

  const handleChangeOrder = () => {
    setShowOrderStatusModal(true);
  };

  const handleUserInfo = () => {
    setShowUserOrderModal(true);
  };
  useEffect(() => {
    
    const fetchData = async () => {
      // Ensure that the product details are available in the Redux store
      order.items.forEach((item) => {
        const product = products.find((p) => p._id === item.productId);
        if (!product) {
          dispatch(fetchProductById(item.productId));
        }
      });
    };

    fetchData();
  }, [dispatch, order.items, products]);

  if (loading) {
    // You can render a loading spinner or placeholder while products are being fetched
    return <div>Loading...</div>;
  }



  return (
    <>
      <tr className='text_spacing'>
        <td>{order._id} </td>
        <td>{order.items.length}</td>
        <td>N {order.total.toLocaleString()}</td>
        <td>

        <span className={
        order.status === "processing" ? "order_processing" :
        order.status === "in-transit" ? "order_transit" :
        order.status === "delivered" ? "order_delivered" :
        order.status === "closed" ? "order_closed" :
        order.status === "declined" ? "order_declined" : null
      }>
    {order.status}
  </span>
        
        </td>
        <td>{order.shippingAddress}</td>
        <td>{ format(new Date(order.orderDate), 'MM/dd/yyyy')}</td>
        <td className='actions'>
          {is_user  ? (
            <button
              className='btn bg-danger text-white'
              onClick={handleOrderStatusClick}
            >
             <FontAwesomeIcon icon={faEye} />
            </button>
          ) : (
            <>
               <button
              className='btn bg-danger text-white'
              onClick={handleOrderStatusClick}
            >
             <FontAwesomeIcon icon={faEye} />
            </button>
            {is_user}
            <button
              className='btn bg-success text-white'
              onClick={handleChangeOrder}
            >
              <FontAwesomeIcon icon={faReceipt} />
            </button>
            <button
              className='btn bg-primary text-white'
              onClick={handleUserInfo}
            >
             <FontAwesomeIcon icon={faUser} />
            </button>

            </>
           

            
          )}
        </td>
      </tr>

      {/* Modal Component */}
      {showOrderModal && (
        <OrderModal order={order} products={products} onClose={() => setShowOrderModal(false)} />
      )}

      {showUserOrderModal && (
        <UserOrderModal order={order} products={products} onClose={() => setShowUserOrderModal(false)} />
      )}


      {showOrderStatusModal && (
        <OrderStatusModal order={order} isSuccess={isSuccess} onClose={() => setShowOrderStatusModal(false)} />
      )}
    </>
  );
};

export default OrderListing;
