import React, { useEffect, useState } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import CreateComplaintModal from '../components/CreateComplaintModal';
import { getAllComplaint, getUserComplaint } from '../app/reducers/complaintSlice';
import { fetchUserOrders } from '../app/reducers/orderSlice';
import ComplaintListing from '../components/ComplaintListing';
import { logout } from '../app/reducers/authSlice';

const Complaint = () => {
/** Navigate , Dispatch */
const navigate = useNavigate()
const dispatch = useDispatch()

// Modal
const [showCreateComplaintModal,setShowCreateComplaintModal] = useState(false)
const [showSetComplaintStatusModal, setCreateComplaintStatusModal] = useState(false)

 const { user } = useSelector((state) => state.auth);
 const {orders} = useSelector((state) => state.orders)
 const {complaints, isSuccess, isError, message} = useSelector((state) => state.complaints)

 const showCreateComplaint = () => {
    setShowCreateComplaintModal(true)
 }

 const closeCreateComplaintModal = () => {
    setShowCreateComplaintModal(false)
 }

 useEffect(() => {
    if(!user){
        navigate('/login')
    }
    if(user && user.userType !== "user")
    {
      dispatch(getAllComplaint())
    }else{
      dispatch(getUserComplaint())
      dispatch(fetchUserOrders())
    }
    
 }, [navigate, user])

 const onLogout = () => {
  dispatch(logout())
  navigate("/login")
}

  return (
    <div className='dashboard'>
        <Sidebar type={user.userType}/>
        <div className='content'>
          {/* {user.userType ? "1": "2"} */}
            <Navbar type={user.userType} onLogout={onLogout}/>
            <div className='contain'>
                <div className='contain-header'>
                        <h4>Ticket(s)</h4>
                        {user.userType === "user" &&
                         <button className='btn btn-muted-blue' onClick={showCreateComplaint}>
                            New Ticket
                        </button>
                        }
                       
                </div>


                <div className='table-container'>  
                <table className='table table-responsive table-hover table-bordered mt-5'>
                  <thead className='table-secondary'>
                    <tr>
                        <th>#</th>
                        <th>Order ID</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Current State</th>
                        <th>Date</th>
                        <th>Last Updated</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                {complaints.map((complaint) => (
                  <ComplaintListing key={complaint._id} complaint={complaint} user={user} isSuccess={isSuccess} />
                ))}
              </tbody>
                  </table>
                  </div>
                
            </div>


        </div>
        {showCreateComplaintModal && 
        <CreateComplaintModal show={showCreateComplaint} handleClose={closeCreateComplaintModal} orders={orders}/>   
    }
    </div>
  
  )
}

export default Complaint