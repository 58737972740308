import React, { useEffect, useState } from 'react'
import { doctor_wallpaper } from '../assets'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { forgotpassword, login, reset } from '../app/reducers/authSlice'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const ForgotPassword = () => {
const MySwal = withReactContent(Swal);

    const [formData, setFormData] = useState({
        email:"",
        })
    
    const {email} = formData
      const navigate = useNavigate()
      const dispatch = useDispatch()
    
      const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth)
    
      const onChange = (e) => {
        setFormData((prevState) =>({
          ...prevState,
          [e.target.name]: e.target.value
        }))
      }
    
      const onSubmit = (e) => {
          e.preventDefault()
            const userData = {email}
            dispatch(forgotpassword(userData))
            // Swal("Password")
            // navigate("/dashboard")
      }
    
      
    
      useEffect(() =>{
        if(isError){
          alert(message)
        }
    
        if(isSuccess){
          MySwal.fire({
            icon: 'success',
            title: "Password Link Sent to Email",
          });
        }
    
        dispatch(reset())
      }, [user, isError, isSuccess, message, navigate, dispatch])
  return (
    <div className='d-flex'>
    <div className='wallpaper-bg'>
      BloodBankNg!
      {/* <img src={doctor_wallpaper} alt="" className='wallpaper-image' /> */}
    </div>

    <div className='auth-section m-5 p-2'>
    <span>! BloodBankNg</span>
            <h4>Forgot Password</h4> 
            <p>Oops Forgot Password ?. Recover Account</p> 
        {isLoading ? ( <p className='mt-4 text-primary font-weight-bold'>Loading ...</p>) : null}
       

        <form onSubmit={onSubmit}>
   
        <div className="row">
          <div className="form-group">
                <label htmlFor="Email Address"></label>
                <input className="form-control" id="myInput" required type="email" value={email} name="email" onChange={onChange} placeholder="Enter Your Email Address"/>
          </div>
          <div className='action-btn mt-5'>
            
            <div>
            <Link to="/login" className='text-danger'>
                   Go back to Login
            </Link>
            </div>
            <div>
                <button className='btn btn-danger'>
                    Request Password Reset
                </button>
            </div>
          </div>
         
        </div>
               
        </form>
    </div>
</div>
  )
}

export default ForgotPassword