import React from 'react'
import { airbnb, coinbase, dropbox } from '../assets'

const Partners = () => {
  return (
    <div className='partners'>
        <img src={coinbase} className='img-fluid' />
        <img src={dropbox} className='img-fluid' />
        <img src={airbnb} className='img-fluid' />
    </div>
  )
}

export default Partners