import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { createProfile, reset } from '../app/reducers/profileSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const MySwal = withReactContent(Swal);

const ProfileForm = ({profile}) => {
  const { message, isSuccess,isError } = useSelector((state) => state.profile);
  const [localGovtSelect, setLocalGovtSelect] = useState('');
  
  // Define your list of LGAs for Lagos and Abuja
  const lagosLGAs = [
    'Agege', 'Ajeromi-Ifelodun', 'Alimosho', 'Amuwo-Odofin', 'Apapa', 
    'Badagry', 'Epe', 'Eti-Osa', 'Ibeju-Lekki', 'Ifako-Ijaiye', 'Ikeja', 
    'Ikorodu', 'Ikeja', 'Kosofe', 'Lagos Island', 'Lagos Mainland', 'Mushin', 
    'Ojo', 'Oshodi-Isolo', 'Shomolu', 'Surulere'
  ];
  const abujaLGAs = ['Abaji', 'AMAC', 'Bwari', 'Gwagwalada', 'Kuje', 'Kwali'];

  const [formData, setFormData] = useState({
    bsnImage: profile ? profile.bsnImage : '',
    buisnessName: profile ? profile.buisnessName : '',
    medicalLicense:   profile ? profile.medicalLicense : '',
    officeAddress: profile ?  profile.officeAddress : '',
    // phoneNumberA: profile.phoneNumberA ||'', // Initialize phoneNumberA in formData
    // phoneNumberB: profile.phoneNumberB || '', // Initialize phoneNumberA in formData
    localGovt : profile ? profile.localGovt : '',
    state:  profile ? profile.state : '',
  });


  const { buisnessName, medicalLicense, officeAddress, phoneNumberA, phoneNumberB, bsnImage, localGovt, state } = formData;

  const handleChange = (e, inputIdentifier) => {
    const value = e.target ? e.target.value : e;

    setFormData((prevState) => ({
      ...prevState,
      [inputIdentifier]: value,
    }));

    if (inputIdentifier === 'phoneNumberA' && !validatePhoneNumber(value)) {
      console.log('Invalid phone number');
    }
  };

  const validatePhoneNumber = (phoneNumberA) => {
    const phoneNumberRegex = /^\d{13}$/;
    return phoneNumberRegex.test(phoneNumberA);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      // If a new file is selected, update the state
      setFormData({
        ...formData,
        bsnImage: file,
      });
    } else {
      // If no new file is selected, keep the previous file in the state
      setFormData({
        ...formData,
        bsnImage: profile ? profile.bsnImage : '',
      });
    }
  };
  

  
  const onSubmit = (e) => {
    e.preventDefault();
    const profileData = { bsnImage, buisnessName, medicalLicense, phoneNumberA, phoneNumberB, officeAddress, localGovt, state };
    
    dispatch(createProfile(profileData));
    if(isError){
      MySwal.fire({
        icon: 'error',
        title:message
      })
    }
    if (isSuccess) {
      MySwal.fire({
        icon: 'success',
        title: 'Profile Recieved, BloodBankNg will get back within 24 hours',
      });
    }
    dispatch(reset())
  };

  return (
    <>
      <section className='contain p-4'>
      <div className='contain-header'>
                <div>
                   <h3 className='contain-title'>Update Profile</h3>
                   <p className='text-sm text-secondary'>
                    Update User Profile with Hospital details here. Kindly Note that Account may be de-activated if Falsified information is detected.
                   </p>
                </div>
       </div>    
       <hr />
        <form onSubmit={onSubmit} encType="multipart/form-data">
        <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'>
              Upload Profile Image
            </label>
            <div className='d-flex flex-column float-end'>
            {profile && 
            
            <img src={`/${profile.bsnImage}`}  className="img-fluid float-end profile_img" alt="" />
            }
              
              
              <input type="file" name="bsnImage" className='btn mt-2 btn-outline-danger' onChange={handleFileChange}              
              required />
            </div>
            
          </div>
          <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'>
              Company Name
              
            </label>
            <input
              className='form-control form-group-input'
              id='customInput'
              name='buisnessName'
              value={buisnessName}
              required
              onChange={(e) => handleChange(e, 'buisnessName')}
              placeholder='Enter Your Business Name'
            />
          </div>
          <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'>
              Medical License Key
            </label>
            <input
              className='form-control form-group-input'
              name='medicalLicense'
              id='customInput'
              value={medicalLicense}
              required
              onChange={(e) => handleChange(e, 'medicalLicense')}
              placeholder='Enter Your Medical License ID'
            />
          </div>

          <hr />

          {/* Phone Number Validation */}
          <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'> Phone Number (1)</label>
            <div className='form-group-input'>
               <PhoneInput
            country={'ng'}
            value={phoneNumberA}
            onChange={(e) => handleChange(e, 'phoneNumberA')}
            className=""
          />
              {!validatePhoneNumber(phoneNumberA) && (
            <p className='text-danger'>Please Enter a Valid 10-digit number</p>
          )}
          </div>
        
            </div>
           

          {/* Phone Number Validation */}
          <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'> Phone Number (2)</label>
            <div className='form-group-input'>
            <PhoneInput
                        country={'ng'}
                        value={phoneNumberB}
                        onChange={(e) => handleChange(e, 'phoneNumberB')}
                      />

{!validatePhoneNumber(phoneNumberB) && (
            <p className='text-danger'>Please Enter a Valid 10-digit number</p>
          )}

            </div>
            
          </div>
          
          {/* Additional test */}

          <hr />
          <div className='form-group form-group-style'>
            <label className='label form-group-label'>Office Address</label>
          <textarea
                    className='form-control form-group-input'
                    id='customInput'
                    rows="10"
                    name='officeAddress'
                    value={officeAddress}
                    required
                    onChange={(e) => handleChange(e, 'officeAddress')}
                    placeholder='Enter Your Office Address'
                  ></textarea>
          </div>

    
            <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'>
              State of Residence
            </label>
            <select
              className='form-control form-group-input'
              name='state'
              id='customInput'
              value={state}
              required
              onChange={(e) => handleChange(e, 'state')}
            >
              <option value=''>Select your state</option>
              <option value='lagos'>Lagos</option>
              <option value='Abuja'>Abuja</option>
              {/* Add more states as needed */}
            </select>
          </div>

          <div className='form-group form-group-style'>
            <label htmlFor='Title' className='label form-group-label' id='labelInput'>
              Local Government Area
            </label>
            <select
              className='form-control form-group-input'
              name='localGovt'
              id='customInput'
              value={localGovt}
              required
              onChange={(e) => handleChange(e, 'localGovt')}
            >
              <option value=''>Select LGA</option>
              {state.toLowerCase() === 'lagos' && lagosLGAs.map(lga => (
                <option key={lga} value={lga}>{lga}</option>
              ))}
              {state.toLowerCase() === 'abuja' && abujaLGAs.map(lga => (
                <option key={lga} value={lga}>{lga}</option>
              ))}
              {/* Add more states and corresponding LGAs as needed */}
            </select>
          </div>

        
          <div className='form-group font-weight-bold button-float'>
                <button className='btn btn-muted-blue'>
                     Create / Update Profile
                </button>
            </div>

          {/* <button className='btn btn-lg btn-danger w-full mt-5 btn-block'></button> */}
        </form>
      </section>
    </>
  );
};

export default ProfileForm;
