import { faEye, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactPaginate from 'react-paginate';
import React, { useState } from 'react'
import { format } from 'date-fns';
import OrderModal from './OrderModal';

const Transaction = ({orders, products}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null)



  const pendingOrders = orders.filter(
    (order) => order.status.toLowerCase() !== 'closed'
  );

  const itemsPerPage = 10; 
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = pendingOrders.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const handlePageClick = (data) => {
      setCurrentPage(data.selected);
    };

    const handleOrderPreview = (order) => {
      setSelectedOrder(order)
      setShowOrderModal(true);
    };

  return (
    <div className='mt-5'>
        <h6 className='text-crimson my-4 text-uppercase spacing-3'>Transaction[s]</h6>
        <div className='table-container'>
            <table className="table table-bordered">
                <thead className='table-secondary text-white-50'>
                  <tr className='bg-primary'>
                      <th>Order Items </th>
                    <th>Total</th>
                    <th>Current Status</th>
                    <th>Due Date</th>
                    <th>Actions</th>
                  </tr>
                  
                </thead>
                <tbody>
                  {currentOrders.map((order) => (
                    <tr key={order._id}>
                      <td>{order.items.length}</td>
                      <td>N {order.total}</td>
                      <td>{order.status}</td>
                      <td>{format(new Date(order.orderDate), 'MM/dd/yyyy')}</td>
                      <td>
                        <button className='btn btn-danger'>
                          <FontAwesomeIcon
                            icon={faPrint}
                            className='text-secondary pointer text-white'
                            onClick={() => handleOrderPreview(order)}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}

                  <ReactPaginate
                    pageCount={Math.ceil(pendingOrders.length / itemsPerPage)}
                    onPageChange={handlePageClick}
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='page-item'
                    nextClassName='page-item'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    pageClassName='page-item'
                    pageLinkClassName='page-link'
                  />
                </tbody>

            </table>

            {showOrderModal && (
                  <OrderModal order={selectedOrder} products={products} onClose={() => setShowOrderModal(false)} />
                )}

        </div>
    </div>
  )
}

export default Transaction