import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import volunteerService from "./volunteerService";

const initialState = {
    volunteers: [],
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message:""
}
export const createVolunteer = createAsyncThunk('volunteer/create', async(volunteer, thunkAPI) => {
    try {
        return await volunteerService.createVolunteer(volunteer)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const allVolunteers = createAsyncThunk("volunteers/all", async(user, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await volunteerService.allVolunteers(token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateVolunteer = createAsyncThunk("volunteers/updateVolunteerById", async({volunteerId, updatedVolunteer}, thunkAPI) =>{
    try {
        const token = thunkAPI.getState().auth.user.token; 
        const response = await volunteerService.updateVolunteerById({volunteerId, updatedVolunteer}, token);
        return response;
    } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})



export const volunteerSlice = createSlice({
    name:"volunteers",
    initialState,
    reducers:{
        reset: (state) => initialState
    },
    extraReducers:(builder) => {
        builder
        .addCase(createVolunteer.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(createVolunteer.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.volunteers.push(action.payload)
            state.message = ''
        })
        .addCase(createVolunteer.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload

        })
        .addCase(allVolunteers.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(allVolunteers.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.volunteers = action.payload
        })
        .addCase(allVolunteers.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateVolunteer.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(updateVolunteer.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
             // Find the index of the updated volunteer in the state
                const index = state.volunteers.findIndex(volunteer => volunteer.id === action.payload.id);

                if (index !== -1) {
                    // If found, update the volunteer
                    state.volunteers[index] = action.payload;
                }
        })
        .addCase(updateVolunteer.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })

    }
})

export const {reset} = volunteerSlice.actions
export default volunteerSlice.reducer