import React from 'react'

const ServiceOffering = () => {
  return (
    <div className='service mt-5'  id="services">
        <div className='service-info'>
            <p> What we Offer</p>
            <h3>
                BloodBankNg Service Offerings
            </h3>
            <p>
            As part of BloodBankNg's,There are stated the initial foreground in the provision of blood banks.
            We are stated that the provision of sounds of reason and lorem
            </p>
        </div>

        <div className='service-cards'>
          <div className='card p-2 service-card'>
                <h3>Blood Inquiry</h3>
                <p>
                    lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem
                </p>
          </div>
          <div className='card p-2 service-card'>
                <h3>Blood Inquiry</h3>
                <p>
                    lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem
                </p>
          </div>
          <div className='card p-2 service-card'>
                <h3>Blood Inquiry</h3>
                <p>
                    lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem
                </p>
          </div>
          <div className='card p-2 service-card'>
                <h3>Blood Inquiry</h3>
                <p>
                    lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem lorem ipsum dolor emet init lorem
                </p>
          </div>
        </div>
    </div>
  )
}

export default ServiceOffering