import React, { Component } from 'react'
import { Link } from 'react-router-dom';

const errorBoundaryStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:"column",
    height: '100vh',
    backgroundColor: '#f8d7da',  // Red background color for error state
    color: '#721c24',  // Dark red text color for error state
    padding: '20px',
    textAlign: 'center',
  };
  

export class ErrorBoundary extends Component {
  state = {hasError: false}

  static getDerivedStateFromError(error){
    return {hasError: true}
  }

  componentDidCatch(error, info){
    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
        return (
          <div style={errorBoundaryStyle}>
            <h1>Oops! Something went wrong.</h1>
            <p>Please try again later.</p>
            <a href="/" style={{ color:"red", cursor:"pointer" }}>Go Back to Home</a>
          </div>
        );
      } else {
        return this.props.children;
      }
    }
}

export default ErrorBoundary
