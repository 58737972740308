import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const ActivateUserAccountModal = ({ user, isSuccess, show, handleClose, handleSave, onAccountChange }) => {
    const [editedUser, setEditedUser] = useState({ ...user });

    const handleInputChange = (e) => {
      setEditedUser({
        ...editedUser,
        [e.target.name]: e.target.value,
      });
    };

    const handleSaveChanges = () => {
        // Pass the updated user data to the parent component
        handleSave(editedUser);
        if (isSuccess) {
          MySwal.fire({
            icon: 'success',
            title: `User Activation Changed`,
          });
        }
        // Close the modal
        handleClose();
      };

  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header className="bg-crimson text-white" closeButton>
      <Modal.Title>User Activate</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <form>
  <div className="mb-3">
    <label htmlFor="userType" className="form-label">
      User Type
    </label>
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id="userRadio"
        name="activate"
        value="primary"
        // checked={editedUser.activate === 'primary'}
        onChange={() => onAccountChange('primary')}
      />
      <label className="form-check-label" htmlFor="userRadio">
        Primary
      </label>
    </div>
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id="adminRadio"
        name="activate"
        value="secondary"
        // checked={editedUser.activate === 'secondary'}
        onChange={() => onAccountChange('secondary')}
      />
      <label className="form-check-label" htmlFor="adminRadio">
        Secondary
      </label>
    </div>
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id="adminRadio"
        name="activate"
        value="deactivate"
        // checked={editedUser.activate === 'deactivate'}
        onChange={() => onAccountChange('deactivate')}
      />
      <label className="form-check-label" htmlFor="adminRadio">
        De- Activate
      </label>
    </div>
  </div>
  {/* Add other input fields for editing */}
</form>


    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleSaveChanges}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ActivateUserAccountModal