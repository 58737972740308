import React from 'react'
import { image_four, specialist } from '../assets'
import { Link } from 'react-router-dom'


const About = () => {
  return (
    <div className='mt-5 about' id="about">
        <div className='about-img'>
        <img className="img-fluid " src={specialist}/>
        </div>
        <div className='about-info'>
            <p>Who are we ? </p>
            <h4>
              Learn About Our Donation Workflow
            </h4>
            <span>
                BloodBankNg enforces Drug & Cosmetic Act, National blood policy standards and guidelines ensuring proper collection & donation,
                 effective management and monitoring the quality and quantity of the donated blood.
                  Considering the national roll out, BloodBankNg has been developed with modular
                   and scalable approach with configurable rule based architecture allowing 
                   customization to easily incorporate specific requirements 
                   from nationwide stakeholders.
            </span>
            <ul>
                <li>
                    24/7 Response Time between within Nigeria 
                </li>
                <li>
                    Delivery with 5 hours
                </li>
            </ul>

            <div className='about-btn mt-5'>
            <Link to="/donate" className='btn btn-danger'>
               Volunteer Now
            </Link>
        </div>
        </div>
        
       

    </div>
  )
}

export default About