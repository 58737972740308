import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { doctor_wallpaper } from '../assets'
import { Link } from 'react-router-dom'
import { register, reset } from '../app/reducers/authSlice'

const Register = () => {
  const [formData, setFormData] = useState({
    firstName:'',
    lastName:"",
    email:"",
    password:"",
    passwordConfirm:""
  })

  const {firstName, lastName, email, password, passwordConfirm} = formData
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth)

  const onChange = (e) => {
    setFormData((prevState) =>({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
      e.preventDefault()

      if(password !== passwordConfirm){
        alert('Passwords do not match')
      }else{
        const userData = { firstName,lastName , email, password}

        dispatch(register(userData))
      }
  }


  useEffect(() =>{
    if(isError){
      alert(message)
    }

    if(isSuccess || user){
        navigate('/dashboard')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])


  return (
    <div className='d-flex'>
        <div className='wallpaper-bg'>
            BloodBankNg !
        </div>

        <div className='auth-section p-5 m-3'>
        
        
            <span>! BloodBankNg</span>
            <h4>Create Account</h4> 


            <p>Welcome to BloodBankNg. Select Method to Create an Account</p>

            {isLoading ? ( <p className='mt-4 text-primary font-weight-bold'>Loading ...</p>) : null}
           



            <form onSubmit={onSubmit}>
       
            <div className="row">
              <div className="flex-form">
                  <div className="form-group">
                    <label htmlFor="FirstName"></label>
                    <input className="form-control" id="myInput"  required type="text" value={firstName} name="firstName" onChange={onChange} placeholder="Enter Your FirstName"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="LastName"></label>
                    <input className="form-control" id="myInput"  required type="text" value={lastName} name="lastName" onChange={onChange} placeholder="Enter Your LastName"/>
                  </div>
              </div>

              <div className="form-group">
                    <label htmlFor="Email Address"></label>
                    <input className="form-control" id="myInput"  required type="email" value={email} name="email" onChange={onChange} placeholder="Enter Your Email Address"/>
              </div>

              <div className="form-group">
                    <label htmlFor="Password"></label>
                    <input className="form-control" id="myInput" required type="password" value={password} name="password" onChange={onChange} placeholder="Enter New Password"/>
              </div>

              <div className="form-group">
                    <label htmlFor="Password"></label>
                    <input className="form-control" required id="myInput"  type="password" value={passwordConfirm} name="passwordConfirm" onChange={onChange} placeholder="Re-Enter Password"/>
              </div>

              <div className='action-btn mt-5'>
                
                <div>
                <Link to="/login" className='text-danger'>
                       I already have an account
                </Link>
                </div>

               

                <div>
                    <button className='btn btn-danger btn-lg'>
                    Create Account
                 </button>
                </div>
                 
               

                 
              </div>
             
            </div>
                   
            </form>
        </div>
    </div>
  )
}

export default Register