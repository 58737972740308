import React, { useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import VolunteerListing from '../components/VolunteerListing'
import Navbar from '../components/Navbar'
import { allVolunteers, reset } from '../app/reducers/volunteerSlice'
import { logout } from '../app/reducers/authSlice'

const Volunteers = () => {
 const {volunteers, isSuccess} = useSelector((state) => state.volunteers)
 const {user} = useSelector((state) => state.auth)
 const navigate = useNavigate()
 const dispatch = useDispatch()

 
useEffect(() => {
    if (!user) {
     navigate('/login')
    }

    if(user.userType !== "admin"){
      navigate('/dashboard')
    }
    
    dispatch(allVolunteers())
    dispatch(reset())
   
  }, [navigate, user, dispatch])

  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
  }


  return (
    <div className='dashboard'>
    <Sidebar type={user.userType}/>
    <div className='content'>
    <Navbar type={user.userType} onLogout={onLogout}/>


    <div className='contain'>
                <div className='contain-header'>
                <h3 className='contain-title'>Volunteers ({volunteers.length})</h3>
              
                </div>  
                <div className='table-container'>
                <table className='table table-responsive table-hover table-bordered'>
                <caption>List of Volunteers</caption>
                  <thead className='table-secondary text-white-50'>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Age</th>
                        <th>Blood Group</th>
                        <th>Blood Type</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                     {volunteers.map((volunteer, i) => (
                    <VolunteerListing key={volunteer._id} volunteer={volunteer} isSuccess={isSuccess} />
                ))} 
                  </tbody>
              
               
              </table>
                </div>
               
            </div>
    </div>
    </div>
  )
}

export default Volunteers