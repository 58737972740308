import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import complaintService from './complaintService'



const initialState = {
    complaints: [],
    isError: false,
    isSuccess: false, 
    isLoading:false, 
    message: ''
}

export const createComplaint = createAsyncThunk('complaint/create', async(complaint, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user._id
        const token = thunkAPI.getState().auth.user.token

        return await complaintService.createComplaint(complaint, userId, token)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message)
         || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getAllComplaint = createAsyncThunk('complaint/all', async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await complaintService.getAllComplaint(token)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message)
         || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getUserComplaint = createAsyncThunk("complaint/users", async(_, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user._id
        const token = thunkAPI.getState().auth.user.token 
        return await complaintService.getUserComplaint(token)
    } catch (error) {
        const message = 
        (error.response && error.response.data && error.response.data.message)
         || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const changeUserComplaintStatus = 
        createAsyncThunk("complaint/status_change", async({ id, editedComplaint }, thunkAPI) => {
            try {
                const token = thunkAPI.getState().auth.user.token
                return await complaintService.changeStatus(id, editedComplaint, token)
            } catch (error) {
                const message = 
                (error.response && error.response.data && error.response.data.message)
                 || error.message || error.toString()
                return thunkAPI.rejectWithValue(message)
            }
})




export const complaintSlice = createSlice({
    name:"complaints",
    initialState,
    reducers :{
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(createComplaint.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createComplaint.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.complaints.push(action.payload)
        })
        .addCase(createComplaint.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(getAllComplaint.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getAllComplaint.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.complaints = action.payload
        })
        .addCase(getAllComplaint.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isLoading = false
            state.message = action.payload
        })
        .addCase(getUserComplaint.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getUserComplaint.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.complaints = action.payload
        })
        .addCase(getUserComplaint.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(changeUserComplaintStatus.pending, (state) => {
            state.isLoading = true
        })
        .addCase(changeUserComplaintStatus.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            const updatedComplaint = action.payload;

            // Find the index of the user with the matching _id in the state.users array
            const complaintIndex = state.complaints.findIndex(complaint => complaint._id === updatedComplaint._id);
        
            // If the user is found, update the user in the array, else add it to the end
            if (complaintIndex !== -1) {
                state.complaints[complaintIndex] = updatedComplaint;
            } else {
                state.complaints.push(updatedComplaint);
            }
        })
    }
})

export const {reset} = complaintSlice.actions
export default complaintSlice.reducer