import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);


const RoleUserModal = ({ user, show, handleClose, handleSave, onRoleChange, isSuccess }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleInputChange = (e) => {
    setEditedUser({
      ...editedUser,
      [e.target.name]: e.target.value,
    });
  };

  const handleSaveChanges = () => {
    // Pass the updated user data to the parent component
    handleSave(editedUser);

    if (isSuccess) {
      MySwal.fire({
        icon: 'success',
        title: `User role updated`,
      });
    }

    // Close the modal
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header className="bg-crimson text-white" closeButton>
        <Modal.Title >Change Role: {user.firstName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form>
  <div className="mb-3">
    <label htmlFor="userType" className="form-label">
      User Type
    </label>
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id="userRadio"
        name="userType"
        value="user"
        // checked={editedUser.userType === 'user'}
        onChange={() => onRoleChange('user')}
      />
      <label className="form-check-label" htmlFor="userRadio">
        User
      </label>
    </div>
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        id="adminRadio"
        name="userType"
        value="admin"
        // checked={editedUser.userType === 'admin'}
        onChange={() => onRoleChange('admin')}
      />
      <label className="form-check-label" htmlFor="adminRadio">
        Admin
      </label>
    </div>
  </div>
  {/* Add other input fields for editing */}
</form>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RoleUserModal;
