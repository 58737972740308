import React, { useEffect, useState } from 'react'
import UserProfile from './UserProfile'
import Recent from './Recent'
import { fetchUserOrders } from '../app/reducers/orderSlice'
import { useDispatch, useSelector } from 'react-redux'
import OrderListing from './OrderListing'
import { useNavigate } from 'react-router'
import { reset } from '../app/reducers/userSlice'
import ReactPaginate from 'react-paginate'

const UserWidgets = ({orders, profile, user}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const itemsPerPage = 10; // Adjust the number of items per page

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');



  // Filter orders based on search query
  const filteredOrders = orders.filter((order) => {
    const orderId = order.orderId || ''; // Ensure orderId is not undefined
    const status = order.status || ''; // Ensure status is not undefined
    const address = order.address || ''; // Ensure address is not undefined

    return (
      orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      address.toLowerCase().includes(searchQuery.toLowerCase())
      // Add more fields for filtering as needed
    );
  });

  // Calculate the index range for the current page
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  return (
    <div>
       <div className='user-contain'>
        <UserProfile profile={profile} user={user} orders={orders} />
        <Recent orders={orders} profile={profile} />
    </div>
    <div className='mt-4'>
    <div className="table-container">
            <table className='table table-bordered'>
              <thead className='table-secondary'>
                <tr>
                  <th>Order ID</th>
                  <th>Items</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Address</th>
                  <th>OrderDate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {user.userType ?
              <tbody>
                {currentOrders.map((order, i) => (
                  <OrderListing key={order._id} order={order} is_user={user.userType}  />
                ))}
              </tbody>
              : null
              }
            </table>

            <ReactPaginate
              pageCount={Math.ceil(filteredOrders.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName='pagination'
              activeClassName='active'
              previousClassName='page-item'
              nextClassName='page-item'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              pageClassName='page-item'
              pageLinkClassName='page-link'
            />
          </div>
    </div>
    </div>
   
  )
}

export default UserWidgets