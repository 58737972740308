import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from './productService'

const initialState = {
    products: [],
    product:null,
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message:''
}

export const createProduct = createAsyncThunk('product/create', async(product, thunkAPI) => {
    try {
        return await productService.createProduct(product)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const editProduct = createAsyncThunk('product/edit', async(product, thunkAPI) => {
    try {
        return await productService.fetchProductById(product)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const getProducts = createAsyncThunk('product/all', async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await productService.getProducts(token)
    } catch (error) {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        message
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

export const fetchProductById = createAsyncThunk(
    'product/fetchProductById',
    async (productId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        return await productService.fetchProductById(productId, token);
        // return response.data; // Assuming your API response has a 'data' property
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
  export const updateProductsById = createAsyncThunk(
    'product/updateProductById',
    async ({productId, updatedProduct}, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const response = await productService.updateProductById({productId, updatedProduct}, token);
        return response;
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  
export const enlistProduct = createAsyncThunk('product/enlist', async(id, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token
    return await productService.enlistProduct(id, token)
  } catch (error) {
    const message = (error.response &&
      error.response.data &&
      error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message)
  }
})

export const deleteProduct = createAsyncThunk('product/delete', async(id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await productService.deleteProduct(id, token)
    } catch (error) {
        const message = "Hello Megan"
      return thunkAPI.rejectWithValue(message)
    }
})






export const productSlice = createSlice({
    name:"products",
    initialState,
    reducers:{
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(createProduct.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(createProduct.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products.push(action.payload)
            state.message = ''
        })
        .addCase(createProduct.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload

        })
        .addCase(updateProductsById.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(updateProductsById.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products.push(action.payload)
        })
        .addCase(updateProductsById.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.isSuccess = false
            state.message = action.payload
        })
        .addCase(getProducts.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(getProducts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products = action.payload
        })
        .addCase(getProducts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteProduct.pending, (state) => {
            state.isLoading = true
          })
          .addCase(deleteProduct.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.products = state.products.filter(
              (product) => product._id !== action.payload.id
            )
          })
          .addCase(deleteProduct.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
          })

          .addCase(enlistProduct.pending, (state) => {
            state.isLoading = true
          })
          .addCase(enlistProduct.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            const updatedProductIndex = state.products.findIndex(product => product._id === action.payload._id);

            if (updatedProductIndex !== -1) {
              state.products[updatedProductIndex] = action.payload;
            }
          })
          .addCase(fetchProductById.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchProductById.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true
            state.product = action.payload
            // console.log('Fetched product:', action.payload);
          })
          .addCase(fetchProductById.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = action.error.message;
          });
          
    }
})

export const {reset} = productSlice.actions
export default productSlice.reducer