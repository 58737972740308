import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { allUsers, changeUserActivity, changeUserRole, reset } from '../app/reducers/userSlice';
import RoleUserModal from './RoleUserModal';
import ActivateUserAccountModal from './ActivateUserAccountModal';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faCheckCircle, faClock, faUser } from '@fortawesome/free-solid-svg-icons';
import ProfileModal from './ProfileModal';

const UserListings = ({ user, isSuccess }) => {
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [activate, setActivate] = useState('');
  const [userType, setUserType] = useState('');
  const dispatch = useDispatch();

  const handleRoleChange = () => {
    setShowRoleModal(true);
  };

  const handleCloseRoleModal = () => {
    setShowRoleModal(false);
  };

  const handleSaveRoleChange = () => {
    dispatch(changeUserRole({ userId: user._id, userType }));
    dispatch(allUsers())
    setShowRoleModal(false);
  };

  const handleActivate = () => {
    setShowAccount(true);
  };

  const handleProfile = ()=> {
    setShowProfile(true)
  }

  
  const handleProfileClose = ()=> {
    setShowProfile(false)
  }

  const handleActivated = () => {
    dispatch(changeUserActivity({ userId: user._id, activate }));
    dispatch(allUsers())
    setShowAccount(false);
  };

  return (
    <tr className='text_spacing'>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email} {user.deactivateRequest ? <span className='bg-crimson text-white rounded-circle px-1'>DA</span>: null}</td>
      <td className='text-center'><span className={user.userType === "user"? "user_type_isUser":"user_type_isAdmin"}>{user.userType}</span></td>
      <td>
      <span className={
        user.activate === "primary" ? "primary_activate" :
        user.activate === "secondary" ? "secondary_activate" :
        user.activate === "deactivate" ? "deactivated" : null
      }>
    {user.activate}
  </span>
      </td>
      {/* <td>{user.createdAt}</td> */}
      <td className='actions'>
        <button className='btn btn-secondary' onClick={handleRoleChange}>
          <FontAwesomeIcon icon={faBriefcase} />
        </button>
        {user.profile &&
          <button className='btn btn-success' onClick={handleProfile}>
          <FontAwesomeIcon icon={faUser} />
        </button>
        }
        <button className='btn btn-danger' onClick={handleActivate}>
          <FontAwesomeIcon icon={faClock} />
        </button>
      
      </td>

      {showRoleModal && (
        <RoleUserModal
          user={user}
          isSuccess={isSuccess}
          show={showRoleModal}
          handleClose={handleCloseRoleModal}
          handleSave={() => handleSaveRoleChange()}
          onRoleChange={(value) => setUserType(value)}
        />
      )}

      {showProfile && (
        <ProfileModal user={user} show={showProfile} handleClose={handleProfileClose}/>
      )}

      {showAccount && (
        <ActivateUserAccountModal
          user={user}
          show={showAccount}
          isSuccess={isSuccess}
          handleClose={() => setShowAccount(false)}
          handleSave={() => handleActivated()}
          onAccountChange={(value) => setActivate(value)}
        />
      )}
    </tr>
  );
};

export default UserListings;
