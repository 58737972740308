import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import VolunteerModal from './VolunteerModal';
import EditVolunteerModal from './EditVolunteerModal';
import { updateVolunteer } from '../app/reducers/volunteerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faReceipt } from '@fortawesome/free-solid-svg-icons';

const VolunteerListing = ({volunteer, isSuccess}) => {
  const [showVolunteerModal, setShowVolunteerModal] = useState(false)
  const [editVolunteerModal, setEditVolunteerModal] = useState(false);
  const {volunteers} = useSelector((state) => state.volunteers)

  const dispatch = useDispatch()
  const handleVolunteerClick = () => {
    setShowVolunteerModal(true);
  };

  const handleVolunteerEditClick = () => {
    setEditVolunteerModal(true);
  };

  const handleVolunteerChange = (updatedVolunteer) => {
    dispatch(updateVolunteer({ volunteerId: volunteer._id, updatedVolunteer }));
    
    setEditVolunteerModal(false);
  };
    return (
       <tr>
        <td>{volunteer.firstName}</td>
        <td>
          {volunteer.lastName}
        </td>
        <td>
          {volunteer.email}
        </td>
        <td>
          {volunteer.age}
        </td>
       
        <td>
          {volunteer.bloodGroup}
        </td>
        <td>
          {volunteer.bloodType}
        </td>
        <td>
          <button className='btn btn-danger mx-1' onClick={handleVolunteerClick}>
              <FontAwesomeIcon icon={faReceipt} />
          </button>
          <button className='btn btn-primary mx-1' onClick={handleVolunteerEditClick}>
              <FontAwesomeIcon icon={faEdit} />
          </button>
        </td>
        {showVolunteerModal && (
            <VolunteerModal volunteer={volunteer}  onClose={() => setShowVolunteerModal(false)}  />
          )}
        {editVolunteerModal && (
            <EditVolunteerModal volunteer={volunteer}
             onClose={() => setEditVolunteerModal(false)}
             isSuccess={isSuccess}
             handleSave={(updatedVolunteer) => handleVolunteerChange(updatedVolunteer)}
             />
        )}
        </tr>
        

          
          
       

    )
}

export default VolunteerListing