import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "./orderService";

const initialState = {
    orders: [],
    isError: false,
    isSuccess:false, 
    isLoading: false, 
    message:''
}

export const fetchAllOrders = createAsyncThunk("orders/all", async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await orderService.fetchAllOrders(token)
    } catch (error) {
        const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        return thunkAPI.rejectWithValue(message);
    }
})

export const fetchUserOrders = createAsyncThunk("orders/users", async(_, thunkAPI) => {
  try {
      const token = thunkAPI.getState().auth.user.token;
      // console.log(token)
      return await orderService.fetchUserOrders(token)
  } catch (error) {
      const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
      return thunkAPI.rejectWithValue(message);
  }
})


export const changeOrderStatus = createAsyncThunk("order/change-status", async({orderId, status}, thunkAPI) =>{
  try {
    const token = thunkAPI.getState().auth.user.token
    return await orderService.changeOrderStatus({orderId, status}, token)
} catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
    return thunkAPI.rejectWithValue(message)
}
})



export const orderSlice = createSlice({
    name:"orders",
    initialState,
    reducers:{
        reset: (state) => initialState
    },

    extraReducers:(builder) => {
        builder
        .addCase(fetchAllOrders.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchAllOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.orders = action.payload;
          })
          .addCase(fetchAllOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
          })
          .addCase(fetchUserOrders.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchUserOrders.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.orders = action.payload;
          })
          .addCase(fetchUserOrders.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
          })
          .addCase(changeOrderStatus.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(changeOrderStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            const updatedOrderIndex = state.orders.findIndex(order => order._id === action.payload._id);

            if (updatedOrderIndex !== -1) {
              state.orders[updatedOrderIndex] = action.payload;
            }
           
          })
          .addCase(changeOrderStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
          });
          
         
    }
})

export const {reset} = orderSlice.actions
export default orderSlice.reducer