import { faKitMedical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState}from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { createVolunteer } from '../app/reducers/volunteerSlice'
// import {useNavigate} from 'nav'

const VolunteerForm = () => {
    const [formData, setFormData] = useState({
        firstName:"",
        lastName:"",
        email:"",
        age:"",
        bloodGroup:"",
        ailment:"",
    })

    const {firstName, lastName, email, age, bloodGroup, ailment} = formData
    const navigate = useNavigate()
    const dispatch = useDispatch()


 
     const onChange = (e) => {
        setFormData((prevState) =>({
          ...prevState,
          [e.target.name]: e.target.value
        }))
      }

      const onSubmit = (e) => {
        e.preventDefault()
        const volunteer = {firstName, lastName, email, age, bloodGroup, ailment}
        console.log(volunteer)
        dispatch(createVolunteer(volunteer))
        setFormData({
            firstName:"",
            lastName:"",
            email:"",
            age:18,
            bloodGroup:"",
            ailment:"",
        })
        navigate("/")
     }

  return (
    <div className='volunteer mt-5'>
        <p>
            <FontAwesomeIcon icon={faKitMedical} className='text-danger'  />
        </p>

        <p>
            You are 30 seconds away from becoming a Donor Ambassador volunteering  for People in Need.
        </p>

        <p className='mt-2'>
            Must be Above the age of 18
        </p>

        <form className='form-volunteer' onSubmit={onSubmit}>
            <div className='form-group'>
                <label>
                    FirstName <span>*</span>
                </label>
                <input id="myInput" name="firstName" className="form-control custom-input" placeholder="Enter FirstName..." value={firstName} onChange={onChange} required />
            </div>
            <div className='form-group mt-3'>
                <label>
                    Last Name <span>*</span>
                </label>
                <input id="myInput" name="lastName" className="form-control custom-input" placeholder="Enter LastName..." value={lastName} onChange={onChange} required/>
            </div>
            <div className='form-group mt-3'>
                <label>
                    Email Address <span>*</span>
                </label>
                <input id="myInput" name="email" type='email' className="form-control custom-input" placeholder="Enter Email Address..."  value={email} onChange={onChange} required/>
            </div>
            <div className='form-group mt-3'>
                <label>
                    Age <span>*</span>
                </label>
                <input id="myInput" name="age" type='number'  min="18"
                max="70" className="form-control custom-input" placeholder="Ideal Age between 18 to 70..." 
                pattern="[1-9][8-9]|[2-6][0-9]|70" value={age} onChange={onChange}/>
            </div>

            <div className='form-group mt-3'>
                <label>
                    Blood Group
                </label>
                <select className='form-control custom-input' name="bloodGroup" id="myInput" value={bloodGroup} onChange={onChange}>
                    <option>Choose ...</option>
                    <option>A</option>
                    <option>B</option>
                    <option>AB</option>
                    <option>O</option>
                </select>
            </div>

            <div className='form-group mt-3' value={ailment} onChange={onChange} >
                <label>
                    Ailments If Any <p className=''>Information Shared is kept confidential</p>
                </label>
                <textarea  className='form-control custom-input' id="myInput" rows="10" cols="14">

                </textarea>
            </div>


            <div className='form-group my-4'>
                <button className='w-full btn btn-lg btn-danger py-2 font-weight-bold'>
                    Submit Form
                </button>
            </div>  
            
        </form>
    </div>
  )
}

export default VolunteerForm