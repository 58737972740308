import axios from "axios"
const API_URI = `https://api.centobloodbank.com/api/volunteers/`

const allVolunteers = async(token) => {
    const config= {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URI, config)
    return response.data
}


const createVolunteer = async(volunteer) => {
    // console.log(volunteer)
    const response = await axios.post(API_URI, volunteer)
    return response.data
}

const updateVolunteerById = async({volunteerId, updatedVolunteer}, token) => {
    const updatedAPI_URI = `${API_URI}/${volunteerId}`
    // console.log(API_URI)
    const config= {
      headers: {
          Authorization: `Bearer ${token}`
      }
    }
    try {
        const response = await axios.put(updatedAPI_URI, updatedVolunteer, config);
        console.log(API_URI)
        return response.data;
    } catch (error) {
        console.log(error.message)
        throw error;
    }
}


const volunteerService = {
    allVolunteers,
    createVolunteer,
    updateVolunteerById
}

export default volunteerService