// CartPage.jsx
import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import CartItem from '../components/CartItem';  // Import CartItem
import { useDispatch, useSelector } from 'react-redux';
import { checkout, getAllCarts, reset as cartReset } from '../app/reducers/cartSlice';
import { fetchProfile } from '../app/reducers/profileSlice'
import { useNavigate } from 'react-router-dom';
import { PaystackButton } from 'react-paystack';
import { getProducts, reset as productReset } from '../app/reducers/productSlice';
// import Swal from 'sweetalert2';
import { logout } from '../app/reducers/authSlice';

const CartPage = () => {
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.profile);
  const { carts } = useSelector((state) => state.carts);
  const { products } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const publicKey = "pk_test_10b973909ed78d71438b563da69f43e90aa0330b";

  // console.log(carts)
  useEffect(() => {
    if(!user){
      navigate('/login')
    }
    if(user){
    dispatch(fetchProfile())
    // console.log(profile)
    }
    if(user.userType !== "user")
    {
      navigate('/dashboard')
    }
    dispatch(getProducts());

    dispatch(getAllCarts());
    dispatch(cartReset())
    dispatch(productReset())
  }, [navigate, dispatch, user]);
  

  const calculateSubtotal = (carts, products) => {
    return carts.reduce((total, cart) => {
      // console.log("Cart Items", !!cart.items);
      if (!cart.items || !Array.isArray(cart.items) || cart.items?.length === 0) {
        return total; // Skip empty cart items
      }
      const cartSubtotal = cart.items.reduce((cartTotal, item) => {
        const product = products.find((p) => p._id === item.productId);
  
        // Handle missing product gracefully
        if (!product) {
          console.warn(
            `Product with ID ${item.productId} not found. Skipping its price in subtotal calculation.`
          );
          return cartTotal;
        }
  
        return cartTotal + product.discountPrice * item.quantity;
      }, 0);
  
      return total + cartSubtotal;
    }, 0);
  };

  /*** Payment */

  const handlePaymentSuccess = () => {
    const orderData = {
        shippingAddress: profile.officeAddress + "," + profile.localGovt + "," + profile.state ,
        contactNumber: profile.phoneNumberA,
    }
    dispatch(checkout(orderData)).unwrap().then((result) => 
      alert("Saved")
    )
  }
  
  
    
  const componentProps = {
    email: user.email,
    amount: calculateSubtotal(carts, products) * 100,
    metadata: {
      name: user.firstName,
    },
    publicKey,
    text: "Pay Now",
    onSuccess: handlePaymentSuccess,
    onClose: () => alert("Wait! Don't leave :("),
  };

  const onLogout = () => {
    dispatch(logout())
    navigate('/login'); // Redirect to the login page

}

  return (
    <div className='dashboard'>
      <Sidebar type={user.userType}/>
      <div className='content'>
        <Navbar onLogout={onLogout} type={user.userType}/>

      
        
        <div className='contain'>
        {user.activate !== "secondary" ? 
          (
            <div className='alert alert-primary px-3'>
              Profile Activation Still Pending
            </div>
          ): null
        
        }
          <h2 className='text-secondary my-3'>My Cart</h2>
          {
            carts ?
            carts.map((cart) => (
              <div key={cart._id} className='card p-3 card-style '>
                <div className=''>
                  <h5 className='font-weight-bold'>Number of items in the cart:
                   {/* {cart.items.length} */}
                   </h5>
                  <table className='table table-responsive'>
                    <thead className='text-uppercase text-secondary cart-sm-head'>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>QTY</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                cart.items.map((item) => (
                  <CartItem
                    key={item.productId}
                    item={item}
                    products={products}
                  />
                ))}
              </tbody>
                  </table>
                </div>

                <div className='checkout-card payout-item-center'>
                  <div className='checkout-info'>
                    <h5>Shopping Method</h5>
                    <div className='mt-2'>
                      <input className='' type='radio' checked readOnly />
                      <label className='text-secondary font-weight-bold'>
                        Shipped to Office Address
                      </label>
                    </div>
                  </div>
                  <div className='checkout-actions'>
                    <div>
                      <p className='text-secondary'>
                        SUBTOTAL : <span className='checkout-sum'>{calculateSubtotal(carts, products).toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}</span>{" "}
                      </p>
                    </div>
                    {profile && user.activate === "secondary" ? 
                    <PaystackButton className='btn btn-lg btn-success w-full mt-3' {...componentProps} />
                      :
                      <button className='btn btn-secondary btn-lg' disabled>Pending ...</button>
                    }
                  </div>
                </div>
              </div>
            )): "Cart Error in Runtime"}
            </div>
      </div>
    </div>
  );
};

export default CartPage;
