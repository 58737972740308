import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { changeOrderStatus, fetchAllOrders } from '../app/reducers/orderSlice';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);


const OrderStatusModal = ({ onClose, order, isSuccess }) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');

  const handleChangeOrder =  () => {
    // try {
        // Dispatch the changeOrderStatus action with the selected status
        dispatch(changeOrderStatus({ orderId: order._id, status }));
        console.log(isSuccess)
        if (isSuccess) {
          console.log("success")
          MySwal.fire({
            icon: 'success',
            title: 'Profile Recieved, BloodBankNg will get back within 24 hours',
          });
        }

      
        // Close the modal
        onClose();
      
        // Fetch updated orders
        dispatch(fetchAllOrders());
        // window.location.reload()
    //   } catch (error) {
    //     // Handle errors, show an alert, etc.
    //     console.error('Error changing order status:', error.message);
    //     alert('Failed to change order status. Please try again.');
    //   }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header className='bg-crimson text-white' closeButton>
        <Modal.Title>Change Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Order ID: <h5>{order._id}</h5></p>

        <form>
          <div className='mt-4'>
            <label htmlFor='' className='ml-3 my-3'>
              Current Status <h5>{order.status}</h5>
            </label>
            <select
              className='form-control'
              name="status"
              id="status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option>Choose ...</option>
              <option value="processing">Processing</option>
              <option value="in-transit">In-Transit</option>
              <option value="dispatched">Dispatched</option>
              <option value="delivered">Delivered</option>
              <option value="closed">Closed</option>
              <option value="declined">Declined</option>
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleChangeOrder}>
          Change Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderStatusModal;
