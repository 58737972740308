import React, { useEffect } from 'react'
import Nav from './Nav'
import VolunteerForm from './VolunteerForm'

const Volunteer = () => {

  return (
    <div className='home'>
        <div  className='section px-2'>
            <Nav />
            <VolunteerForm />
        </div>
    </div>
  )
}

export default Volunteer