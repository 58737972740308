import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/complaints/';

const getAllComplaint = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URI, config);
  return response.data;
};

const getUserComplaint = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URI}user`, config);
  return response.data;
};

const createComplaint = async (complaint, userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Assuming userId needs to be included in the complaint data
  const dataToSend = {
    ...complaint,
    userId: userId,
  };

  const response = await axios.post(API_URI, dataToSend, config);
  return response.data;
};

const changeStatus = async (id, editedComplaint, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(
      API_URI + `status/${id}`,
      editedComplaint,
      config,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const complaintService = {
  getAllComplaint,
  getUserComplaint,
  createComplaint,
  changeStatus,
};

export default complaintService;
