import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { createProduct } from '../app/reducers/productSlice'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const ProductForm = () => {
    const {message, isSuccess, products} = useSelector((state) => state.products)
    const [formData, setFormData] = useState({
        productName:"",
        productDesc:"",
        productPrice:0,
        productStock:0,
        discountPrice:0,
        category:"",
        productImage:null
    })

    const {productName, productDesc, productPrice, productStock, discountPrice, category, productImage} = formData
    const navigate = useNavigate()
    const dispatch = useDispatch()


    const onChange = (e) => {
        setFormData((prevState) =>({
          ...prevState,
          [e.target.name]: e.target.value
        }))
      }
      const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
          ...formData,
          productImage: file,
        });
      };

      const onSubmit = (e) => {
        e.preventDefault();
        const product = { productName, productDesc, productPrice, productStock, discountPrice, category, productImage };

        // Check if the product already exists
        const ifProductExists = CheckProduct(productName, products);

        dispatch(createProduct(product));
        if (isSuccess) {
            MySwal.fire({
                icon: 'success',
                title: "Product Created!",
            });
            setFormData({
                productName: "",
                productDesc: "",
                productPrice: 0,
                productStock: 0,
                discountPrice: 0,
                category: "",
                productImage: null
            });
        navigate("/products")
        } else {
            MySwal.fire({
                icon: 'error',
                title: message,
            });
        }
    };

    const CheckProduct = (productName, products) => {
        return products.some((product) => product.productName === productName);
    };
    

  return (
    <div className='p-4'>
        <form onSubmit={onSubmit} encType="multipart/form-data">
             
        <div className='form-group mt-4 form-group-style'>
            <label  id="labelInput" className='form-group-label'> Product Image</label>
                
                <input type="file" name="productImage" className='btn mt-2 btn-outline-danger' onChange={handleFileChange} required/>
              
            </div>
            
            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Enter Product's Name</label>
                <input className='form-control form-group-input' id="customInput" placeholder='Enter Product Name' name='productName' value={productName} onChange={onChange} required/>
            </div>
            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Enter Product Description</label>
                <input className='form-control form-group-input' id="customInput" placeholder='Enter Product Desc' name='productDesc' value={productDesc} onChange={onChange} required/>
            </div>


            <hr />
            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Enter Product Stock (items in stock)</label>
                <input id="customInput" type="number" className='form-control form-group-input' placeholder='Enter Product Stock' name='productStock' value={productStock} onChange={onChange} required/>
            </div>

            <hr />

            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Enter Product Pricing</label>
                <input type='number' id="customInput" className='form-control form-group-input' placeholder='Enter Product Price' name='productPrice' value={productPrice} onChange={onChange} required/>
            </div>

           
            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Enter Discount Price</label>
                <input id="customInput" type="number" className='form-control form-group-input' placeholder='Enter Product Discount' name='discountPrice' value={discountPrice} onChange={onChange} required/>
            </div>

            <hr />

            <div className='form-group  form-group-style'>
                <label id="labelInput" className='form-group-label'>Category</label>
                <select id="customInput" className='form-select form-group-input' name='category' value={category} onChange={onChange} required>
                    <option value="">Choose..</option>
                    <option value="blood">Blood</option>
                    <option value="aids">Medical Aids</option>
                    <option value="equipments">Equipments</option>
                </select>
            </div>

            <br />
           

            <div className='form-group font-weight-bold button-float'>
                <button className='btn btn-muted-blue'>
                    Create New Product
                </button>
            </div>

            {/* <div className='form-group mt-5 font-weight-bold'>
                <button className='btn btn-muted w-100'>
                    Create New Product
                </button>
            </div> */}







        </form>
    </div>
  )
}

export default ProductForm