import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/users';

const register = async (userData) => {
  const response = await axios.post(API_URI, userData);
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data));
  }

  return response.data;
};

const changePassword = async ({ currentPassword, newPassword }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    currentPassword,
    newPassword,
  };

  const response = await axios.post(`${API_URI}/change-password`, data, config);
  localStorage.removeItem('user');
  return response.data;
};

const login = async (userData) => {
  const response = await axios.post(API_URI + '/login', userData);
  try {
    if (response.data) {
      localStorage.setItem('user', JSON.stringify(response.data));
      return response.data;
    }
  } catch (error) {
    throw error.response.data.message;
  }
};

const forgotPassword = async (userData) => {
  const response = await axios.post(API_URI + '/forgotpassword', userData);
  return response.data;
};

const resetPassword = async ({ password, resetToken }) => {
  const response = await axios.post(API_URI + `/resetpassword/${resetToken}`, {
    password,
  });
  return response.data;
};

const deactivateRequest = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_URI + '/user_deactivate',
    userId,
    config,
  );
  return response.data;
};

const logout = async () => {
  localStorage.removeItem('user');
};

const authService = {
  register,
  changePassword,
  forgotPassword,
  deactivateRequest,
  resetPassword,
  login,
  logout,
};

export default authService;
