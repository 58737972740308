import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";



const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false, 
    isLoading: false,
    message:''
}

/** Register User */
export const register = createAsyncThunk('auth/register', async(user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const login = createAsyncThunk("auth/login", async(user, thunkAPI) =>{
    try {
        return await authService.login(user)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const logout = createAsyncThunk("auth/logout", async(_, thunkAPI) =>{
    const token = thunkAPI.getState().auth.user.token
    await authService.logout(token)
})

export const changePassword = createAsyncThunk('auth/changePassword', async ({ currentPassword, newPassword }, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token
    try {
      return await authService.changePassword({ currentPassword, newPassword }, token);
    //   return { success: true };
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
  });

 export const forgotpassword = createAsyncThunk("auth/forgotPassword", async(user, thunkAPI) => {
    try {
        return await authService.forgotPassword(user);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
 })

 export const resetPassword = createAsyncThunk("auth/resetpassWord", async(payload, thunkAPI) => {
    try {
        return await authService.resetPassword(payload);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
 })

 
export const deactivateRequest = createAsyncThunk("user/deactivate", async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        const userId = thunkAPI.getState().auth.user._id
        return await authService.deactivateRequest(userId, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers:{
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.message = ''
            state.isError = false
        }
    },

    extraReducers:(builder) => {
        builder
        .addCase(register.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(register.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
            state.message = ''
        })
        .addCase(register.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
        .addCase(login.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
            state.message = ''
        })
        .addCase(login.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })
       
        .addCase(changePassword.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(changePassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
            state.message = ''
        })
        .addCase(changePassword.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
        })

        .addCase(deactivateRequest.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deactivateRequest.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
            state.message = '';
        })
        .addCase(deactivateRequest.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
            state.user = null;
        })
        .addCase(forgotpassword.pending, (state) => {
            state.isLoading = true
        })
        .addCase(forgotpassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
        })
        .addCase(forgotpassword.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false 
            state.message = action.payload
        })

        .addCase(resetPassword.pending, (state) => {
            state.isLoading = true
        })
        .addCase(resetPassword.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.message = action.payload
        })
        .addCase(resetPassword.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false 
            state.message = action.payload
        })
        // 
        .addCase(logout.fulfilled, (state) =>{
            state.user = null
        })
    }
})

export const {reset} = authSlice.actions
export default authSlice.reducer