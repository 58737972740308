import React from 'react'
import { image_one } from '../assets'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCogs, faEdit } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const UserProfile = ({profile, user, orders}) => {

  const pendingOrdersCount = orders.filter((order) => order.status !== 'closed' && order.status !== "declined").length;
const closedOrdersCount = orders.filter((order) => order.status === 'closed').length;
  return (
    <div className="card user__profile mr-3">
      <div className="d-flex align-items-center justify-content-center flex-column">
      {profile && 
        <img src={`/${profile.bsnImage}`}  className="img-fluid profile__img" alt="" />
      }
       
        <h3 className="text-muted-blue">{profile && profile.buisnessName}</h3>
        <div className="alert-primary py-2">{user.firstName} {user.lastName}</div>
        <div className="alert-primary text-secondary py-1">{user.email}</div>
      </div>
      <div className='mt-3 profile__list'>
          <div className='profile__listing'>
              <h4>{pendingOrdersCount}</h4>
              <p>Pending Orders</p>
          </div>
          <div className='profile__listing'>
              <h4>{closedOrdersCount}</h4>
              <p>Closed Orders</p>
          </div>
      </div>

      <div className="settings">
        <Link to="/profile">
          <FontAwesomeIcon icon={faEdit} className='profile__action' />
        </Link>
        
      </div>
    </div>
  )
}

export default UserProfile