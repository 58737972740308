
import { faBars, faL } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const Nav = () => {
  const location = useLocation()
  const [menu, setMenu] = useState(false)

  const showMenu = () => {
    setMenu(!menu)
  }

  const isHomePage = location.pathname === ""
  return (
    <nav className=''>
        <div className='nav-icon'>
            BloodBankNg
        </div>
        <div className='nav-menu'>
            <FontAwesomeIcon icon={faBars} onClick={showMenu}  />
        </div>
        <div className='nav-listing'>
          <ul>
            <li>
              <Link to="/" className='navlinks'>Home</Link>
            </li>
            {location.pathname === "/" ?  
              <>
                 <li>
              <a href="#about" className='navlinks' >About Us</a>
            </li>
            <li>
              <a href='#services' className='navlinks'>Services</a>
            </li>
              </>
             : null }
         
            <li>
              <Link to="/donate" className='navlinks'>Donate</Link>
            </li>
          </ul>

          <Link to="/register" className='btn btn-danger btn-large nav-button'>
              Get Started
          </Link>
        </div>
        {menu && (
        <div style={{ position:"absolute", top:38, right: 33, zIndex:3, border:"1px solid #eee", backgroundColor:"#eee" }}>
          <ul style={{ listStyle:"none" }}>
          <li>
              <Link to="volunteer" className='navlinks mt-4' style={{ textDecoration:"none", color:"red" }}>Volunteer</Link>
            </li>
            <li>
            <Link to="/register" className='btn btn-danger btn-large nav-button'>
              Get Started
          </Link>
            </li>
            {/* Add more pages as needed */}
          </ul>
        </div>
      )}

    </nav>
  )
}

export default Nav