import React from 'react'
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Tooltip, Legend, XAxis, YAxis } from 'recharts'

export const OrderChart = () => {
    const orderChartData = [
        { name: 'Day 1', orders: 10 },
        { name: 'Day 2', orders: 15 },
        { name: 'Day 3', orders: 20 },
        // Add more data as needed
      ];
  return (
    <div>
      <h2>Order Chart</h2>
      <LineChart width={400} height={300} data={orderChartData}>
        <Line type="monotone" dataKey="orders" stroke="#8884d8" />
        <Tooltip />
        <Legend />
      </LineChart>
    </div>
  )
}


export const BarCharts = ({pendingOrder, closedOrder, declinedOrder}) => {
    const barChartData = [
        { name: 'Pending', Orders: pendingOrder,  color: '#FF5733' },
        { name: 'Closed', Orders: closedOrder, color:"#33FF57" },
        { name: 'Declined', Orders: declinedOrder, color:"#5733FF" },
        // Add more data as needed
      ];
    
    return(
        <div>
        <h2>Orders</h2>
        <BarChart width={300} height={300} data={barChartData}>
          <Bar dataKey="Orders" fill="#8884d8" />
          <XAxis dataKey="name" type="category" />
          <YAxis type="number" />
        
          <Tooltip />
          <Legend />
          
        </BarChart>
      </div>
    )
}

export const VolunteerChart = () => {
    const volunteerChartData = [
        { name: 'Volunteer 1', hours: 10 },
        { name: 'Volunteer 2', hours: 15 },
        { name: 'Volunteer 3', hours: 20 },
        // Add more data as needed
      ];
    return (
        <div>
      <h2>Volunteer Chart</h2>
      <PieChart width={400} height={300}>
        <Pie dataKey="hours" data={volunteerChartData} cx="50%" cy="50%" outerRadius={60} fill="#8884d8" label />
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
    )
}