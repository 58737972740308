import React from 'react'
import { Modal } from 'react-bootstrap'

const ProfileModal = ({user, show, handleClose}) => {
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header className='bg-crimson text-white'>
            <Modal.Title>
                User's Profile
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Buisness Image</p>
                <p className='text-secondary'>{user.profile.buisnessName}</p>
            </div>
            {user.profile.bsnImage &&
                <div className='user__profile_range'>
                    <p className='small text-secondary'>Buisness Name</p>
                    <img src={`/${user.profile.bsnImage}`}  className="img-fluid profile__img my-2" alt="" />
                </div>
            }
           
            <div className='user__profile_range'>
                <p className='small text-secondary'>Medical License Key</p>
                <p className='text-secondary'>{user.profile.medicalLicense}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Local Government Area</p>
                <p className='text-secondary'>{user.profile.localGovt}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>State</p>
                <p className='text-secondary'>{user.profile.state}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Contact Numbers</p>
                <p className='text-secondary'>{user.profile.phoneNumberA} | {user.profile.phoneNumberB}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Office Address</p>
                <p className='text-secondary'>{user.profile.officeAddress}</p>
            </div>
        </Modal.Body>
    </Modal>
  )
}

export default ProfileModal