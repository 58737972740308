import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const OrderModal = ({ order, products, onClose }) => {
  const getProductDetails = (productId) => {
    // Find the product in the products array by productId
    return products.find((product) => product._id === productId);
  };

useEffect(() => {
  console.log('OrderModal useEffect triggered');
}, [products]);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton className="bg-crimson text-white">
        <Modal.Title>Order Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Order ID: <span className='font-weight-bold text-uppercase' style={{ fontSize:"20px" }}>{order._id}</span> </p>
        <p>Items:</p>
        <ul>
        {order.items.map((item) => {
            const product = getProductDetails(item.productId);
            return (
              <li key={item._id}>
                <p>Product Name: {product ? product.productName : 'Product not found'}</p>
                <p>Quantity: {item.quantity}</p>
                {/* Add more product details as needed */}
              </li>
            );
          })}
        </ul>
        <p>Total: {order.total}</p>
        <p>Status: {order.status}</p>
        <p>Shipping Address: {order.shippingAddress}</p>
        <p>Contact Number: {order.contactNumber}</p>
        <p>Order Date: { format(new Date(order.orderDate), 'MM/dd/yyyy')}</p>
        {/* Add more details as needed */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OrderModal;
