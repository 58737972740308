import React, { useEffect, useState } from 'react'
import ProductForm from '../components/ProductForm'
import ProductListings from '../components/ProductListings'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts, reset } from '../app/reducers/productSlice'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { logout } from '../app/reducers/authSlice'


const Products = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {products, isSuccess} = useSelector((state) => state.products)
  const {user} = useSelector((state) => state.auth)
  const [currentPage, setCurrentPage] = useState(0); // Page index starts from 0 in react-paginate
  const pageSize = 10;

useEffect(() => {
  if (!user) {
   navigate('/login')
  }
  if(user.userType !== "admin"){
    navigate('/dashboard')
  }
  dispatch(getProducts())
  dispatch(reset())
 
}, [navigate, user])

const handlePageChange = ({ selected }) => {
  setCurrentPage(selected);
};

const totalPages = Math.ceil(products.length / pageSize);
const startIndex = currentPage * pageSize;
const endIndex = Math.min(startIndex + pageSize, products.length);
const currentProducts = products.slice(startIndex, endIndex);

const onLogout = () => {
  dispatch(logout())
  navigate('/login')
}

  return (
    <div className='dashboard'>
      <Sidebar type={user.userType}/>
      <div className='content'>
      <Navbar onLogout={onLogout} type={user.userType}/>
      <div className='contain'>
            <div className='contain-header'>
                <h3 className='contain-title'>Products ({products.length})</h3>
                <Link to="/create-product" className='btn btn-muted-blue'>
                    Create New Product
                </Link>
            </div>

            {/* <div className='contain-header'>
                <div>
                   <h3 className='contain-title'>Edit Product</h3>
                   <p className='text-sm text-secondary'>
                    Update Detail Information about this product 
                   </p>
                </div>
               
                <Link to="/products" className='btn btn-lg btn-muted-blue'>
                    Product
                </Link>
            </div> */}
              
              {/* <section className='m-4'>
                  <ProductForm />
              </section> */}
              {/* <section className='m-4'> */}
              <div className='table-container'>

                <table className='table table-responsive table-hover table-bordered'>
                  <thead className='table-secondary'>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        {/* <th>Description</th> */}
                        <th>Price</th>
                        <th>Discount Price</th>
                        <th>Stock</th>
                        <th>Category</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                     {currentProducts.map((product, i) => (
                    <ProductListings key={product._id} product={product} isSuccess={isSuccess} />
                ))} 
                  </tbody>
                {/* </table> */}
               
              </table>
              </div>


              <ReactPaginate
            pageCount={totalPages}
            onPageChange={handlePageChange}
            containerClassName="pagination"
              activeClassName="active"
              previousClassName="page-item"
              nextClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              pageClassName="page-item"
              pageLinkClassName="page-link"
          />
      {/* </section> */}
      </div>
      </div>
    </div>
  )
}

export default Products