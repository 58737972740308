import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllOrders, fetchUserOrders } from '../app/reducers/orderSlice'


const Recent = ({orders, profile}) => {

const pendingOrdersCount = orders.filter((order) => order.status === 'processing').length;
const closedOrdersCount = orders.filter((order) => order.status === 'closed').length;
  return (
    <div className="recent card p-5 ml-1">

        <div>
            <p>Company Name</p>
            <p>{profile ? profile.buisnessName : 'NIL'}</p>
        </div>

        <div>
            <p>Orders List</p>
            <p>{orders.length}</p>
        </div>

        <div>
            <p>Phone Number</p>
            <p>{profile ? "+" + profile.phoneNumberA : "NIL" }</p>
        </div>

        <div>
            <p>Office Address</p>
            <p>{profile ? profile.officeAddress : "NIL"}</p>
        </div>
 
        <div>
            <p>Pending Orders</p>
            <p>{pendingOrdersCount}</p>
        </div>

        <div>
            <p>Completed Orders</p>
            <p>{closedOrdersCount}</p>
        </div>

    </div>
  )
}

export default Recent