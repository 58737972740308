import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import Login from "./screens/Login";
import UserProfile from "./screens/UserProfile";
import Products from "./screens/Products";
import ProductDetail from "./screens/ProductDetail";
import Shop from "./screens/Shop";
import Order from "./screens/Order";
import CreateProduct from "./screens/CreateProduct";
import CartPage from "./screens/CartPage";
import ListOrders from "./screens/ListOrders";
import Users from "./screens/Users";
import ForgotPassword from "./screens/ForgotPassword";
import Home from "./screens/Home";
import Volunteer from "./components/Volunteer";
import Volunteers from "./screens/Volunteers";
import OrderCheckout from "./screens/OrderCheckOut";
import ListUserOrders from "./screens/ListUserOrders";
import ResetPassword from "./screens/ResetPassword";
import Complaint from "./screens/Complaint";
// import Profile from "./screens/Profile"



function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/donate" element={<Volunteer />} />
            <Route path="/volunteers" element={<Volunteers />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/products" element={<Products />} />
            <Route path="/carts" element={<CartPage />} />
            <Route path="/order-checkout" element={<OrderCheckout />} />
            <Route path="/create-product" element={<CreateProduct />} />
            <Route path="/products/:id" element={<ProductDetail />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/orders" element={<Order />} />
            <Route path="/list-orders" element={<ListOrders />} />
            <Route path="/list-user-orders" element={<ListUserOrders />} />
            <Route path="/users" element={<Users />} />
            <Route path="/tickets" element={<Complaint />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:resetToken" element={<ResetPassword />} />
        </Routes>
    </Router>
  );
}

export default App;
