import React, {useEffect, useState} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { resetPassword } from '../app/reducers/authSlice'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'



const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { resetToken } = useParams();
  const MySwal = withReactContent(Swal);

  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth)


  const [formData, setFormData] = useState({
    password:"",
    passwordConfirm:""
    })
  const {password, passwordConfirm} = formData

  const onChange = (e) => {
    setFormData((prevState) =>({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault();
  
    if(password !== passwordConfirm){
      alert('Passwords do not match')
    }else{
      const userData = { password:password, resetToken}
      console.log(userData)
      dispatch(resetPassword(userData))
      // console.log(userData)
    }
  }

  
 
  useEffect(() =>{
    if(isError){
     alert(message)
    }

    if(isSuccess){
      MySwal.fire({
        icon: 'success',
        title: "Password Reset Successfully",
      });
    }

    // dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])


  return (
    <div className="d-flex">
       <div className='wallpaper-bg'>
      BloodBankNg!
      {/* <img src={doctor_wallpaper} alt="" className='wallpaper-image' /> */}
    </div>

    <div className='auth-section m-5 p-5'>
    <span>! BloodBankNg</span>
            <h4>Reset Password</h4> 
            <p>Reset to your New Password</p> 
        {/* {isLoading ? ( <p className='mt-4 text-primary font-weight-bold'>Loading ...</p>) : null} */}
       

        <form onSubmit={onSubmit}>
   
        <div className="row">
          <div className="form-group">
                <label htmlFor="New Password"></label>
                <input className="form-control" id="myInput" required type="password" value={password} name="password" onChange={onChange} placeholder="Enter Your New Password"/>
          </div>

          <div className="form-group">
                <label htmlFor="New Password"></label>
                <input className="form-control" id="myInput" required type="password" value={passwordConfirm} name="passwordConfirm" onChange={onChange} placeholder="Confirm Password"/>
          </div>
          <div className='action-btn mt-5'>
            
            <div>
            <Link to="/register" className='text-danger'>
                   I don't already have an account
            </Link>
            </div>
            <div>
                <button className='btn btn-danger btn-lg'>
                   Password Reset
                </button>
            </div>
          </div>
         
        </div>
               
        </form>
    </div>
    
    </div>
  )
}

export default ResetPassword