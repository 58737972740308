import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOrders, reset } from '../app/reducers/orderSlice';
import OrderListing from '../components/OrderListing';
import ReactPaginate from 'react-paginate';
import { getProducts } from '../app/reducers/productSlice';
import { logout } from '../app/reducers/authSlice';

const ListOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orders, isSuccess } = useSelector((state) => state.orders);
  const { user } = useSelector((state) => state.auth);
  const { products } = useSelector((state) => state.products);
  const itemsPerPage = 10; // Adjust the number of items per page

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('pending');


  // const handleOrderStatus = (orde) => {
  //   setSelectedUser(user);
  //   setShowEditModal(true);
  // };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }

    if(user.userType !== "admin"){
      navigate("/dashboard")
    }

    if(isSuccess){

    }

    dispatch(fetchAllOrders());
    dispatch(getProducts());
    dispatch(reset());


  }, [dispatch, user, navigate]);

  // Filter orders based on search query
  const filteredOrders = orders && orders.filter((order) => {
    const orderId = order.orderId || ''; // Ensure orderId is not undefined
    const status = order.status || ''; // Ensure status is not undefined
    const address = order.address || ''; // Ensure address is not undefined

     return (
      orderId.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (selectedStatus === 'all' || status === selectedStatus) &&
      address.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  // Calculate the index range for the current page
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };
  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
}

  return (
    <div className='dashboard'>
      <Sidebar type={user.userType} />
      <div className='content'>
        <Navbar onLogout={onLogout} type={user.userType}/>
        <div className='contain'>
          <div className='contain-header'>
            <h4>Orders ({orders.length})</h4>

            <div className='search-container w-60 my-4'>
              <input
                type='text'
                className='form-control '
                placeholder='Search by name, email, or user type'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: '1090px' }}
              />
            </div>

           

            <Link to='/shop' className='btn btn-dark'>
              Make New Purchase
            </Link>
          </div>


          <select className='form-select order__select my-4' value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}>
            <option value={''}>Select Status</option>
            <option value={'processing'}>Processing Orders</option>
            <option value={'in-transit'}>In Transit Orders</option>
            <option value={'delivered'}>Delivered Orders</option>
            <option value={'closed'}>Closed Orders</option>
            <option value={'declined'}>Declined Orders</option>
            <option value={'all'}>All Orders</option>
          </select>

           <div className="table-container">
            <table className='table table-bordered'>
              <thead className='table-secondary'>
                <tr>
                  <th>Order ID</th>
                  <th>Items</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Address</th>
                  <th>OrderDate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order, i) => (
                  <OrderListing key={order._id} order={order} isSuccess={isSuccess}  />
                ))}
              </tbody>
            </table>

            <ReactPaginate
              pageCount={Math.ceil(filteredOrders.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName='pagination'
              activeClassName='active'
              previousClassName='page-item'
              nextClassName='page-item'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              pageClassName='page-item'
              pageLinkClassName='page-link'
            />
          </div>
          </div>
        </div>
      </div>
  );
};

export default ListOrders;
