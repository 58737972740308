import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { allUsers } from '../app/reducers/userSlice';




const UserOrderModal = ({ order, products, onClose }) => {
    const dispatch = useDispatch();
    const { users, loading } = useSelector((state) => state.users);
    const [loadedUsers, setLoadedUsers] = useState([]);
  
    // ... (existing functions)
    const getProductDetails = (productId) => {
        // Find the product in the products array by productId
        return products.find((product) => product._id === productId);
        };
  
    const getUserDetails = (userId) => {
      // Find the user in the loadedUsers array by userId
      const user = loadedUsers.find((user) => user._id === userId);
      return user || { email: 'User Information Loading...' }; // Default to a message if the user is not found
    };
  
    useEffect(() => {
      if (products.length > 0 && !loadedUsers.length) {
        dispatch(allUsers())
          .then(() => setLoadedUsers(users))
          .catch((error) => console.error('Error fetching users:', error));
      }
    }, [dispatch, products, loadedUsers, users]);
  
    if (loading) {
      return <p>Loading user information...</p>;
    }

    const orderUser = getUserDetails(order.userId);
  
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton className="bg-crimson text-white">
          <Modal.Title>User's Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          orderUser || orderUser !== undefined   ? 
          <div>
          <p>User Email: {orderUser.email }</p>
          <p>User Name: {orderUser.firstName } {orderUser.lastName}</p>
         <p>Company : {orderUser.profile && orderUser.profile.buisnessName }</p>
         <p>Office Address : {orderUser.profile &&  orderUser.profile.officeAddress}</p>
          <p>Lga / State : { orderUser.profile && orderUser.profile.localGovt } / {orderUser.profile &&  orderUser.profile.state}</p>
          <p>Contact Numbers : +{ orderUser.profile && orderUser.profile.phoneNumberA } |  +{ orderUser.profile &&  orderUser.profile.phoneNumberB}</p>
           </div> : "Loading"
        } 

        <hr />
       
          {order.items.map((item) => {
            const product = getProductDetails(item.productId);
            const user = getUserDetails(order.userId);
            return (
              <div key={item._id}>
                <p>Product Name: {product ? product.productName : 'Product not found'}</p>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  export default UserOrderModal;