import { configureStore } from "@reduxjs/toolkit";
import authReducer from './reducers/authSlice'
import profileReducer from "./reducers/profileSlice";
import productReducer from "./reducers/productSlice";
import cartReducer from "./reducers/cartSlice"
import orderReducer from "./reducers/orderSlice";
import userReducer from "./reducers/userSlice";
import volunteerReducer from "./reducers/volunteerSlice";
import complaintReducer from "./reducers/complaintSlice";

export const store = configureStore({
    reducer:{
        auth: authReducer,
        profile: profileReducer,
        products: productReducer,
        carts: cartReducer,
        orders: orderReducer,
        users: userReducer,
        volunteers: volunteerReducer,
        complaints: complaintReducer
    }
})