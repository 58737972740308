import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../app/reducers/authSlice';
import UserAccountModal from './UserAccountModal';

const ProfilePassword = ({user}) => {
  const dispatch = useDispatch();
  const { passwordChangeStatus } = useSelector((state) => state.auth);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false)

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      // Passwords do not match, handle accordingly (show error, etc.)
      console.log("erroe!")
      return;
    }
    console.log("")

    dispatch(changePassword({ currentPassword, newPassword }));
  };

  const deactivateAccount = () => {
    setDeactivateModal(true)
  }


  return (
    <div className='contain'>
      <div className='contain-header'>
                <div>
                   <h3 className='contain-title'>Reset Password</h3>
                   <p className='text-sm text-secondary'>
                      Wish to reset this user's password ?
                   </p>
                </div>
       </div>    
       <hr />
      <form>
        <div className='form-group form-group-style'>
          <label htmlFor='current_password' className='label form-group-label' id='labelInput'>
            Current Password
          </label>
          <input
            className='form-control form-group-input'
            id='current_password customInput'
            type={showPassword ? 'text' : 'password'}
            name='current_password'
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
            placeholder='Enter Your Current Password'
          />
        </div>

        <div className='form-group form-group-style'>
          <label htmlFor='new_password' className='label form-group-label' id='labelInput'>
            New Password
          </label>
          <input
            className='form-control form-group-input'
            id='new_password'
            type={showPassword ? 'text' : 'password'}
            name='new_password'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            placeholder='Enter Your New Password'
          />
        </div>

        <div className='form-group form-group-style'>
          <label htmlFor='confirm_password' className='label form-group-label' id='labelInput'>
            Re-Enter New Password
          </label>
          <div className='form-group-input flex-column'>
            <input
                      className='form-control'
                      id='confirm_password'
                      type={showPassword ? 'text' : 'password'}
                      name='confirm_password'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      placeholder='Re-Enter Your New Password'
                    />

                    <button className='btn btn-outline-secondary mt-5' type='button' onClick={togglePasswordVisibility}>
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  </div>
          </div>


        

          <div className='form-group font-weight-bold d-flex align-content-center justify-content-between mt-5'>
          <div>
               <button className='btn btn-light text-danger' type='button' 
                onClick={deactivateAccount}
                >
                DeActivate Account {user && user.deactivateRequest ? "!": "11"}
                </button>
            </div>

            <div>
               <button className='btn btn-muted-blue' type='button'  onClick={handleChangePassword}>
                Change Password
                </button>
            </div>
               
            </div>
        

        {/* <button
          className='btn btn-lg btn-danger w-full mt-5 btn-block'
          type='button'
          onClick={handleChangePassword}
        >
          Change Password
        </button> */}
      </form>

      {passwordChangeStatus && (
        <div>
          <p>{passwordChangeStatus.success ? 'Password changed successfully' : 'Failed to change password'}</p>
          {/* <button onClick={}>Close</button> */}
        </div>
      )}

      {deactivateModal && (
        <UserAccountModal  show={deactivateAccount} handleClose={() => setDeactivateModal(false)} user={user} />
      )}
    </div>
  );
};

export default ProfilePassword;
