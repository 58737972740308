import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/carts';

const getAll = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.get(API_URI, config);
    // console.log('API Response:', response.data);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

const addProductToCart = async (productId, quantity, token) => {
  const config = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ productId, quantity }),
  };

  try {
    const response = await fetch(API_URI, config);

    if (!response.ok) {
      throw new Error('Failed to Add Product to the Cart');
    }

    return response.json();
  } catch (error) {
    console.error('Error adding product to cart:', error.message);
    throw error; // Re-throw the error to be caught by the calling code
  }
};

const updateCartItemQuantity = async (productId, quantity, token) => {
  const config = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  const data = { productId, quantity };

  const response = await axios.put(`${API_URI}/updatequantity`, data, config);

  return response.data;
};

const removeItemFromCart = async (productId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(`${API_URI}/${productId}`, config);
  return response.data;
};

const checkout = async (userId, orderData, token) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await axios.post(`${API_URI}/checkout`, orderData, config);

    // Assuming you want to check for a specific status code, like 200
    if (response.status === 200) {
      return response.data; // Use response.data to access the response body
    } else {
      throw new Error(`Failed to checkout - Status: ${response.status}`);
    }
  } catch (error) {
    // Handle errors here, you might want to log the error for debugging purposes
    console.error('Error during checkout:', error.message);

    throw new Error('Failed to checkout'); // Rethrow the error for the calling code to handle
  }
};

const cartService = {
  getAll,
  addProductToCart,
  updateCartItemQuantity,
  removeItemFromCart,
  checkout,
};

export default cartService;
