import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { login, reset } from '../app/reducers/authSlice'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InternetStatus from '../components/InternetStatus'

const MySwal = withReactContent(Swal);

const Login = () => {
const [formData, setFormData] = useState({
    email:"",
    password:"",
    })

const {email, password} = formData
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user, isLoading, isError, isSuccess, message} = useSelector((state) => state.auth)

  const onChange = (e) => {
    setFormData((prevState) =>({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const onSubmit = (e) => {
      e.preventDefault()
        const userData = {email, password}
        dispatch(login(userData))
        navigate("/dashboard")
  }

  

  useEffect(() =>{
    if(isError){
      MySwal.fire({
        icon: 'error',
        title: message,
      });
    }
    if(!user){
      navigate('/login')
    }


   if(user && user.activate === "deactivate"){
    alert("Deactivated")
   }

    if(user){
        navigate('/dashboard')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  return (
    <div className='d-flex'>
        
         <div className="wallpaper-bg">
         BloodBankNg !
      </div>

        <div className='auth-section m-5 p-1'>

            <span>! BloodBankNg</span>
            <h4 className="">Sign Into Your Account</h4> 


            <InternetStatus />

            <p>Welcome Back!. Select Method to Login</p> 

            {isLoading ? ( <p className='mt-4 text-primary font-weight-bold'>Loading ...</p>) : null}

            <Link to="/register" className="text-danger">
                       I don't have an account
                </Link>
           

            <form onSubmit={onSubmit}>
       
            <div className="row">

              <div className="form-group">
                    <label htmlFor="Email Address"></label>
                    <input className="form-control" id="myInput" required type="email" value={email} name="email" onChange={onChange} placeholder="Enter Your Email Address"/>
              </div>

              <div className="form-group">
                    <label htmlFor="Password"></label>
                    <input className="form-control" id="myInput" required type="password" value={password} name="password" onChange={onChange} placeholder="Enter New Password"/>
              </div>

              <div className='action-btn mt-5'>
                
                <div>
                <Link to="/forgotpassword" className="text-danger">
                       I can't remember my password
                </Link>
                </div>

               

                <div>
                    <button className='btn btn-danger btn-lg'>
                        Login
                    </button>
                </div>
              </div>
             
            </div>
                   
            </form>
        </div>
    </div>
  )
}

export default Login