import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { createComplaint } from '../app/reducers/complaintSlice'

const CreateComplaintModal = ({handleClose, show , orders}) => {
 const dispatch = useDispatch()
 const [formData, setFormData] = useState({
    type: '',
    desc:'',
    orderId: ''
 })
 const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Dispatch the createComplaint action with the form data
    dispatch(createComplaint(formData));

    // Close the modal
    handleClose();
  };
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header className='bg-crimson text-white'>
            <Modal.Title>
                Create New Ticket 
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                <div className="mb-3 form-group">
                    <label htmlFor="type" className="form-label">
                        Ticket Type 
                    </label>
                    <select
                    className="form-control"
                    id="type"
                    name="type"
                    onChange={handleChange}
                    value={formData.type}
                    required
                    >
                    <option value="">Choose ...</option>
                    <option value="payment">PAYMENT</option>
                    <option value="delivery">DELIVERY</option>
                    <option value="requests">REQUEST</option>
                    <option value="other">OTHER</option>
                    </select>
                </div>
                {
                    formData.type == "delivery" ? 
                    <div className="mb-3 form-group">
                    <label htmlFor="type" className="form-label">
                        Order - ID
                    </label>
                    <select
                    className="form-control"
                    id="orderId"
                    name="orderId"
                    onChange={handleChange}
                    value={formData.orderId}
                    >
                    <option value="">Choose ...</option>
                    {orders.map((order) => (
                        // Use parentheses to implicitly return the JSX
                        <option key={order._id} value={order._id} className='text-uppercase'>
                            {order._id}
                            </option>
                        ))}
                    </select>
                </div> 
                 : null
                }

                <div className="mb-3 form-group">
                    <label htmlFor="desc" className="form-label">
                       Description 
                
                    </label>
                    <textarea className='form-control text-uppercase' name="desc" rows={8} cols={3} onChange={handleChange}
                    value={formData.desc} required>

                    </textarea>
                </div>

                <div className='mt-4'>
                    <button className='btn btn-muted-blue'>
                        Send Ticket
                    </button>
                </div>
                </form>
            </Modal.Body>
       
    </Modal>
  )
}

export default CreateComplaintModal
