import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import cartService from "./cartService";

const initialState = {
    carts: [],
    isError: false, 
    isSuccess: false, 
    isLoading: false,
    message:""
}

export const getAllCarts = createAsyncThunk('carts/all', async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await cartService.getAll(token);
        
    } catch (error) {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})


export const addProductToCart = createAsyncThunk('cart/addProductToCart', async(productId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        const quantity = 1;
        return await cartService.addProductToCart(productId, quantity, token)
    } catch (error) {
        const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

export const updateCartItem = createAsyncThunk('cart/updateQuantity', async({productId, quantity}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token;
        return await cartService.updateCartItemQuantity(productId, quantity, token);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) ||
        error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
})

export const removeItemFromCart = createAsyncThunk(
    "cart/removeItemFromCart",
    async (productId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        return await cartService.removeItemFromCart(productId, token);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const checkout = createAsyncThunk('checkout/submit', async (orderData, { getState, rejectWithValue }) => {
    try {
      const token = getState().auth.user.token;
      const { userId } = getState().auth.user; // Replace 'userId' with the correct field name for the user ID
      return await cartService.checkout(userId, orderData, token);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  
  

export const cartSlice = createSlice({
    name: "carts",
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers:(builder) => {
        builder
        .addCase(getAllCarts.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getAllCarts.fulfilled, (state, action) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = true
            state.carts = action.payload
            const newCarts = action.payload;
            // Loop through the new carts
            newCarts.forEach(newCart => {
              // Check if the cart already exists in the state
              const existingCart = state.carts.find(cart => cart._id === newCart._id);
          
              if (existingCart) {
                // If the cart already exists, update the existing cart
                state.carts = state.carts.map(cart => {
                  if (cart._id === newCart._id) {
                    return newCart;
                  }
                  return cart;
                });
              } else {
                // If the cart doesn't exist, add it to the carts array
                state.carts = [...state.carts, newCart];
              }
            });
        })
        .addCase(getAllCarts.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.isSuccess = false
        }) 

    .addCase(addProductToCart.pending, (state) => {
        state.isLoading = true
    })
    .addCase(addProductToCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const newCartItem = action.payload;

        // Check if the cart already exists in the state
        const existingCart = state.carts.find(cart => cart._id === newCartItem._id);
      
        if (existingCart) {
          // If the cart already exists, update the existing cart
          state.carts = state.carts.map(cart => {
            if (cart._id === newCartItem._id) {
              return newCartItem;
            }
            return cart;
          });
        } else {
          // If the cart doesn't exist, add it to the carts array
          state.carts = [...state.carts, newCartItem];
        }
    })
    .addCase(addProductToCart.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
    })
    .addCase(updateCartItem.pending, (state) => {
        state.isLoading = true
    })
    .addCase(updateCartItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
    
      // Log the payload for debugging
      // console.log('updateCartItem.fulfilled payload:', action.payload);
    
      // Assuming action.payload is a single updated cart
      const updatedCart = action.payload;
    
      // Find the index of the existing cart in state.carts
      const existingCartIndex = state.carts.findIndex(cart => cart._id === updatedCart._id);
    
      if (existingCartIndex !== -1) {
        // If the cart exists, update it
        state.carts[existingCartIndex] = updatedCart;
      } else {
        // If the cart doesn't exist, add it to the carts array
        state.carts.push(updatedCart);
      }
    })
    
    
      .addCase(updateCartItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(removeItemFromCart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeItemFromCart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const removedProductId = action.payload.productId;
      
        // Update only the cart from which the item was removed
        state.carts = state.carts.map(cart => {
          if (cart.items.some(item => item.productId === removedProductId)) {
            // If this cart contains the removed item, update it
            return {
              ...cart,
              items: cart.items.filter(item => item.productId !== removedProductId)
            };
          }
          // Otherwise, return the original cart
          return cart;
        });
      })
      
      .addCase(removeItemFromCart.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(checkout.pending, (state) => {
        state.isLoading = true;
    })
    .addCase(checkout.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
    })
    .addCase(checkout.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
    });
      
    }
})

export const {reset} = cartSlice.actions
export default cartSlice.reducer