import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { checkout } from '../app/reducers/cartSlice';
import { useNavigate } from 'react-router';
import { fetchAllOrders, reset } from '../app/reducers/orderSlice';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';

const Order = () => {
 const [shippingAddress, setShippingAddress] = useState('');
 const [contactNumber, setContactNumber] = useState('');
 const dispatch = useDispatch()
 const navigate = useNavigate()

  const {orders} = useSelector((state) => state.orders)
  const {user} = useSelector((state) => state.auth)


 const handleCheckout = () => {
    const orderData = {
        shippingAddress,
        contactNumber,
        // ... other fields as needed
    };

    dispatch(checkout(orderData));
    navigate("/shop")
};

useEffect(() => {
    if (!user) {
      navigate('/login')
     }  

    dispatch(fetchAllOrders())
    dispatch(reset())
  }, [navigate, dispatch, user])

  return (
    <div className='dashboard'>
    <Sidebar />
    <div className='content'>
        <Navbar />
        <div className='contain'>
            <div className='contain-header'>
                    <h4>Orders {orders.length}</h4>
            </div>
            <div className='form-group'>
            <textarea
                    placeholder="Enter shipping address"
                    className='form-control'
                    value={shippingAddress}
                    onChange={(e) => setShippingAddress(e.target.value)}
            >

            </textarea>
                <input
                    type="number"
                    placeholder="Enter Contact Phone number (+234)"
                    className='form-control mt-5'
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                />
                <button onClick={handleCheckout} className='btn btn-primary w-full mt-4'>Checkout</button>
            </div>
            
        </div>
    </div>
    
</div>
  )
}

export default Order