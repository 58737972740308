import axios from 'axios';

const API_URI = 'https://api.centobloodbank.com/api/users/';

const allUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URI, config);
  return response.data;
};

const changeUserRole = async ({ userId, userType }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_URI + 'user_role',
    { userId, userType },
    config,
  );
  return response.data;
};

const getProfiles = async ({ userId }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`${API_URI}/profile/${userId}`);
  return response.data;
};

const changeUserActivity = async ({ userId, activate }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_URI + 'user_activity',
    { userId, activate },
    config,
  );
  return response.data;
};

const userService = {
  allUsers,
  changeUserRole,
  // deactivateRequest,
  getProfiles,
  changeUserActivity,
};

export default userService;
