import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserOrders, reset } from '../app/reducers/orderSlice';
import OrderListing from '../components/OrderListing';
import ReactPaginate from 'react-paginate';
import { logout } from '../app/reducers/authSlice';
import { fetchProfile } from '../app/reducers/profileSlice';


const ListUserOrders = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { orders } = useSelector((state) => state.orders);
    const { user } = useSelector((state) => state.auth);
    const itemsPerPage = 10; // Adjust the number of items per page
  
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');

    const onLogout = () => {
      dispatch(logout())
      navigate('/login'); // Redirect to the login page
  }

  
    useEffect(() => {
      if (!user) {
        navigate('/login');
        return
      }

      if(user.userType !== "user")
      {
        navigate("/dashboard")
        return
      }
      dispatch(fetchProfile())
      dispatch(fetchUserOrders());
      dispatch(reset());
  
  
    }, [dispatch, user, navigate]);
  

    // Filter orders based on search query
    const filteredOrders = orders.filter((order) => {
      const orderId = order.orderId || ''; // Ensure orderId is not undefined
      const status = order.status || ''; // Ensure status is not undefined
      const address = order.address || ''; // Ensure address is not undefined
  
      return (
        orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        address.toLowerCase().includes(searchQuery.toLowerCase())
        // Add more fields for filtering as needed
      );
    });
  
    // Calculate the index range for the current page
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);
  
    // Change page
    const handlePageClick = (data) => {
      setCurrentPage(data.selected);
    };
  return (
    <div className='dashboard'>
      
      <Sidebar type={user.userType} />
      <div className='content'>
        <Navbar onLogout={onLogout} type={user.userType} />
        <div className='contain'>
          <div className='contain-header'>
            <h4>My Orders ({orders.length})</h4>
            <Link to="/shop" className='btn btn-dark'>
              View Sales
            </Link>
          </div>
          <div className='search-container w-60 my-4'> 
            <input
              type='text'
              className='form-control '
              placeholder='Search by name, email, or user type'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className='table-container'>
            <table className='table table-responsive table-hover'>
              <thead className='thead-dark'>
              <tr>
                  <th>Order ID</th>
                  <th>Items</th>
                  <th>Total</th>
                  <th>Status</th>
                  <th>Address</th>
                  <th>OrderDate</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order) => (
                  <OrderListing key={order._id} order={order} is_user={true}/>
                ))}
              </tbody>
            </table>

            <ReactPaginate
              pageCount={Math.ceil(filteredOrders.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="page-item"
              nextClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              pageClassName="page-item"
              pageLinkClassName="page-link"
            />
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default ListUserOrders