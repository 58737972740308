import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/orders';
const API_URI_CARTS = '/api/carts';

const fetchAllOrders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URI, config);
  return response.data;
};

const fetchUserOrders = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URI}/users`, config);
  return response.data;
};
const changeOrderStatus = async ({ orderId, status }, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    API_URI_CARTS + '/order-status',
    { orderId, status },
    config,
  );
  return response.data;
};

const orderService = {
  fetchAllOrders,
  fetchUserOrders,
  changeOrderStatus,
};

export default orderService;
