import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../app/reducers/authSlice'
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { fetchProfile } from '../app/reducers/profileSlice'
import Widgets from '../components/Widgets'
import Transaction from '../components/Transaction'
import { allUsers } from '../app/reducers/userSlice'
import { fetchAllOrders, fetchUserOrders } from '../app/reducers/orderSlice'
import { getProducts } from '../app/reducers/productSlice'
import { allVolunteers } from '../app/reducers/volunteerSlice'
import UserWidgets from '../components/UserWidgets'
import {  BarCharts, OrderChart, VolunteerChart } from '../components/charts/OrderChart'
import Filter from '../components/admincomponents/Filter'
import { AdminDashboard } from '../components/admincomponents/Admin'



const Dashboard = () => {
    /** Navigate, Dispatch */
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [filteredOrders, setFilteredOrders] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filteredVolunteers, setFilteredVolunteers] = useState([]);


    // Required States from Redux Store
    const {user} = useSelector((state) => state.auth)
    const {users} = useSelector((state) => state.users)
    const {volunteers} = useSelector((state) => state.volunteers)
    const {products} = useSelector((state) => state.products)
    const {orders} = useSelector((state) => state.orders)
    const {profile} = useSelector((state) => state.profile)


    // Find the Orders For User
    // const userOrders = user ? orders.filter(order => order.userId === user.id) : [];
    const pendingOrder = orders ? orders.filter(order => order.status !== "closed" && order.status !== "declined").length : []
    const declinedOrder = orders ? orders.filter(order => order.status === "declined").length : []
    const closedOrder = orders ? orders.filter(order => order.status === "closed").length : []


    useEffect(() => {
      if(!user){
        navigate('/login')
      }

      dispatch(allUsers())
      if(user && user.userType === "user"){
        dispatch(fetchUserOrders())
      }

      if(user && user.userType === "admin"){
        dispatch(fetchAllOrders())
        dispatch(allUsers())
      }
      
      dispatch(getProducts())
      dispatch(allVolunteers())
      dispatch(reset())

      if(user){ 
      dispatch(fetchProfile())
      console.log("Filtered Users:", filteredUsers);
      }
    }, [user, navigate, dispatch])


    const onLogout = () => {
        dispatch(logout())
        navigate("/login")
    }

    const handleFilter = ({ startDate, endDate }) => {
      // Filtering Orders
      const filteredOrdersResult = orders.filter(
        (order) =>
          new Date(order.createdAt) >= startDate && new Date(order.createdAt) <= endDate
      );
      setFilteredOrders(filteredOrdersResult);
  
      // Filtering Users
      const filteredUsersResult = users.filter(
        (user) =>
          new Date(user.createdAt) >= startDate && new Date(user.createdAt) <= endDate
      );
      setFilteredUsers(filteredUsersResult);
      console.log(filteredUsers, users)

  
      // Filtering Volunteers
      const filteredVolunteersResult = volunteers.filter(
        (volunteer) =>
          new Date(volunteer.createdAt) >= startDate &&
          new Date(volunteer.createdAt) <= endDate
      );
      setFilteredVolunteers(filteredVolunteersResult);
    };

  return (
    <>
    {/* Admin UI View */}
    {user && user.userType === "admin" &&  <div className='dashboard'>
    <Sidebar type={user.userType} />
    <div className='content'>
    <Navbar onLogout={onLogout} type={user && user.userType} />
      <div className='contain d-flex'>
      <div style={{flex:3, width:"100%", alignItems:"center" }}>
          <h3 className='text-crimson mb-4'>{"Admin Dashboard"}</h3>
          
          <Filter onFilter={handleFilter} style={{width:"100"}}/>

          <AdminDashboard orders={filteredOrders.length > 0 ? filteredOrders : orders}
           users={filteredUsers.length > 0 ? filteredUsers : users}
            volunteers={filteredVolunteers.length > 0 ? filteredVolunteers : volunteers} products={products}
             
            />

      </div>
      </div>
    </div>



    {/* User View UI  */}
    </div>  }
    {user && user.userType === "user" &&   <div className='dashboard'>
    <Sidebar type={user.userType} />
    <div className='content'>
      <Navbar onLogout={onLogout}  type={user.userType}/>
      <div className='contain d-flex'>
        <div style={{ flex:3, width:"100%", alignItems:"center" }}>
        {
          user && user.activate !== "secondary" ? 

           <div className='alert alert-primary font-weight-bold'>
          Please create your  profile & await approval to be able to make orders. 
        </div>: null
        }
       
            <div className='user__dashboard_header'>
              <h5 className='text-secondary'>
                {user && user.firstName + ' ' + user.lastName}
              </h5>
              <div>
                <Link to="/list-user-orders" className='btn btn-secondary'>
                        View Orders ({orders.length})
                </Link>
                <Link to="/shop" className='btn btn-muted-blue mx-1'>
                      Go Shopping
                </Link>
              </div>
            </div>  
            <UserWidgets orders={orders} profile={profile} user={user} />

        </div>
       
        <div style={{ flex:"1", paddingLeft:"10px",display:"flex", flexDirection:"column", alignContent:"center", justifyContent:"space-evenly" }}>
        <BarCharts pendingOrder={pendingOrder} closedOrder={closedOrder} declinedOrder={declinedOrder}/>
             
           </div>
      </div>
   
    </div>

    {/*  */}
    </div>}
    </>
    
  )
}

export default Dashboard