import { Link } from "react-router-dom";

const Navbar = ({ onLogout, type }) => {
  return (
    <nav className='navbar w-90'>
      <div>
        {/* <input className='form-control' placeholder='Search' /> */}
      </div>
      <div className="nav-item dropdown mx-4">
        <a className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
          Actions
        </a>
        {type == "user" ? 
        <div className="dropdown-menu">
          <Link to="/dashboard" className="dropdown-item">Home </Link>
          <Link to="/list-user-orders" className="dropdown-item">Orders</Link>
          <Link to="/shop" className="dropdown-item">Shop</Link>
          <Link to="/cart" className="dropdown-item">Cart</Link>
          <Link to="/profile" className="dropdown-item">Profile</Link>
          <Link to="/tickets" className="dropdown-item">Tickets</Link>
          <span className='dropdown-item w-full'>
            <button className='btn btn-danger' onClick={onLogout}>
              Logout
            </button>
          </span>
        </div>:  
        
        <div className="dropdown-menu">
          <span className='dropdown-item w-full'>
            <button className='btn btn-danger' onClick={onLogout}>
              Logout
            </button>
          </span>
        </div>

        }
    
      </div>
    </nav>
  );
};

export default Navbar;
