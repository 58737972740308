import React from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import {  reset } from '../app/reducers/orderSlice'; // Assuming you have an orderSlice with a placeOrder action
import { useNavigate } from 'react-router-dom';

const OrderCheckout = () => {
  const { user } = useSelector((state) => state.auth);
  const { carts } = useSelector((state) => state.carts);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePlaceOrder = () => {
    // Assuming you have a function to calculate the total and gather necessary order details
    const orderDetails = {
      userId: user.id,
      // Add other necessary details based on your implementation
    };

    // dispatch(placeOrder(orderDetails));
    dispatch(reset());

    // Redirect the user to a success or confirmation page
    navigate('/order-confirmation');
  };

  return (
    <div className='dashboard'>
      <Sidebar />
      <div className='content'>
        <Navbar />
        <div className='contain'>
          <h2>Order Checkout</h2>
          {Array.isArray(carts) && carts.length > 0 ? (
            <div className='card p-3'>
              <div>
                {/* Display order details or a summary here */}
                <h4>Order Summary</h4>
                {/* Add relevant order details based on your implementation */}
                <p>Total Items: {carts[0].items.length}</p>
              </div>
              <button className="mt-5 btn btn-lg w-full btn-primary" >
                Place Order
              </button>
            </div>
          ) : (
            <p>No items in the cart.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderCheckout;
