import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const Filter = ({onFilter}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
  
    const handleStartDateChange = (date) => {
      setStartDate(date);
    };
  
    const handleEndDateChange = (date) => {
      setEndDate(date);
    };

    const handleFilterClick = () => {
        // if (onFilter) {
            console.log({startDate, endDate})
          onFilter({ startDate, endDate });
        // }
      };

  return (
    <div className="date-range-picker-container">
    <div className="date-picker-wrapper">
      <label>Start Date</label>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Start Date"
        className="date-picker"
      />
    </div>
    <div className="date-picker-wrapper">
      <label className='font-weight-bold'>End Date</label>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="End Date"
        className="date-picker"
      />
    </div>
    <button className='btn btn-muted-blue mt-4' onClick={handleFilterClick}>Filter</button>
  </div>
  )
}

export default Filter