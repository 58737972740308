import React from 'react'
import { Modal } from 'react-bootstrap'

const PreviewComplaintModal = ({show, handleClose, complaint}) => {
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header className='bg-crimson text-white'>
            <Modal.Title>
                    VIEW TICKET
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='user__profile_range'>
                <p className='small text-secondary'>Status</p>
                <p className='text-secondary'>{complaint.status}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Type</p>
                <p className='text-secondary'>{complaint.type}</p>
            </div>
            <div className='user__profile_range'>
                <p className='small text-secondary'>Status</p>
                <p className='text-secondary'>{complaint.desc}</p>
            </div>
            {complaint.statusInfo && 
                <div className='user__profile_range mt-5'>
                <p className='small text-secondary'>Status Information</p>
                <p className=''>{complaint.statusInfo}</p>
            </div>
            
            }
           
            

        </Modal.Body>
    </Modal>
  )
}

export default PreviewComplaintModal