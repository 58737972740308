import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {  useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { logout, reset } from '../app/reducers/authSlice'
import ProfileForm from '../components/ProfileForm'
import { createProfile, fetchProfile } from '../app/reducers/profileSlice'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import ProfilePassword from '../components/ProfilePassword'
import { allUsers } from '../app/reducers/userSlice'

const UserProfile = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)
    const {profile} = useSelector((state) => state.profile)

    useEffect(() => {
      if(!user){
        navigate('/login')
      }
      dispatch(fetchProfile())
      dispatch(allUsers())
     
      dispatch(fetchProfile())

      
    }, [user, navigate])

    const onLogout = () => {
        dispatch(logout())
        navigate('/login')
    }


  return (
      <div className='dashboard'>
        <Sidebar type={user && user.userType} />
        <div className='content'>
        <Navbar onLogout={onLogout} type={"user"}/>

        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="true">
              Profile 
            </button>
            <button className="nav-link" id="nav-password-tab" data-bs-toggle="tab" data-bs-target="#nav-password" type="button" role="tab" aria-controls="nav-password" aria-selected="false">
              Change Password
            </button>
          </div>
        </nav>
       
      <section className='contain'>
        <div className="tab-content" id="nav-tabContent">
        <div className="tab-pane fade show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
          <ProfileForm profile={profile} />
        </div>
        <div className="tab-pane fade" id="nav-password" role="tabpanel" aria-labelledby="nav-password-tab">
          <ProfilePassword user={user}/>
        </div>
      </div> 
          </section>
        </div>
    
        
         
      </div>
  )
}

export default UserProfile