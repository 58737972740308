import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { deactivateRequest } from '../app/reducers/authSlice';

const UserAccountModal = ({show, handleClose, user}) => {
  const [editedUser, setEditedUser] = useState({ ...user });
  const [emailInput, setEmailInput] = useState('');
  const isEmailValid = emailInput.trim() !== '' && emailInput === editedUser.email; 
  const dispatch = useDispatch()

  const handleEmailChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleSendRequest = () => {
    dispatch(deactivateRequest())
    alert("Successful")
  };


  return (
    <Modal show={show} onHide={handleClose}>
    <Modal.Header className="bg-crimson text-white" closeButton>
      <Modal.Title>Deactivate Account </Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form>
        <div className="mb-3">
            <label htmlFor="userType" className="form-label">
            Do you wish to close your account `<span className='font-weight-bold'>{editedUser.email}</span>` with BloodBankNg?. 
            <p className='text-danger'>
                  Note that account will be closed with 7 days after the request is made & you wont be able to login with this afterwards.
            </p>
          
            </label>
            <Form.Group controlId="emailInput">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter your email" onChange={handleEmailChange} value={emailInput} />
          </Form.Group>
            </div> 
        </form>
        
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" onClick={handleSendRequest} disabled={!isEmailValid}>
        Send Request
      </Button>
    </Modal.Footer>
    </Modal>
  )
}

export default UserAccountModal