import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { fetchProductById, getProducts, reset, updateProductsById } from '../app/reducers/productSlice'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom';
import { logout } from '../app/reducers/authSlice';

const ProductDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.auth)
  const productState = useSelector((state) => state.products);
  const {isSuccess, isError, message} = useSelector((state) => state.products)

  const [formData, setFormData] = useState({
    productName: '',
    productDesc: '',
    productPrice: 0,
    productStock: 0,
    discountPrice: 0,
    productImage: null,
    category: '',
  });

  useEffect(() => {
    dispatch(fetchProductById(id));
    if(isError){
      alert(message)
    }
    if(isSuccess && window.location.href === "/products/:id"){
      alert("Success")
      navigate('/products')
    }
  }, [dispatch, id, message, isError]);

  useEffect(() => {
    if(!user){
      navigate('/login')
    }

    if(user.userType !== "admin")
    {
      navigate("/dashboard")
    }
    // Check if productState.product is defined before updating formData
    if (productState.product) {
      setFormData({
        productName: productState.product.productName || '',
        productDesc: productState.product.productDesc || '',
        productPrice: productState.product.productPrice || null,
        productStock: productState.product.productStock || null,
        discountPrice: productState.product.discountPrice || null,
        productImage: productState.product.productImage || null,
        category: productState.product.category || '',
      });
    }
  }, [productState.product, navigate, user]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
        // If a new file is selected, update the state
        setFormData((prevFormData) => ({
            ...prevFormData,
            productImage: file,
        }));
    }
};

  

  const onSubmit = (e) => {
    // console.log(formData);
    e.preventDefault();
    const payload = {
      productId: id,
      updatedProduct: formData,
    };


   
      dispatch(updateProductsById(payload)) 
     

  };

  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
  }
      
  
    return (
      <div className='dashboard'>
        <Sidebar type={user.userType}/>
        <div className='content'>
            <Navbar onLogout={onLogout} type={user.userType}/>
            <div className='contain p-5'>
            <div className='contain-header'>
                <div>
                   <h3 className='contain-title'>Edit Product</h3>
                   <p className='text-sm text-secondary'>
                    Update Detail Information about this product 
                   </p>
                </div>
               
                <Link to="/products" className='btn btn-lg btn-muted-blue'>
                    Product
                </Link>
            </div>
            <hr />
              <div>
              {productState.product ? (
          <form onSubmit={onSubmit} encType='multipart/form-data'>
          <div className='form-group mt-4 form-group-style'>
               <label  id="labelInput" className='form-group-label'> Product Image</label>
                
            <div className='d-flex flex-column float-end'>

            {formData.productImage &&
              <img src={`/uploads/${formData.productImage}`}  className="img-fluid float-end profile_img" alt="" />
             }

              

                <input type="file" name="productImage" className='btn mt-2 btn-outline-danger' onChange={handleFileChange} />
                </div>
              
            </div>
           
          <div className='form-group form-group-style'>
              <label id="labelInput" className='form-group-label'>Product Name</label>
              <input
                className='form-control form-group-input'
                id="customInput"
                placeholder='Enter Product Name'
                name='productName'
                value={formData.productName}
                onChange={onChange}
                required
              />
            </div>

          <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Product Description</label>
                <input id="customInput"
                className="form-control form-group-input"
                placeholder="Enter Product Name"
                name="productDesc"
                value={formData.productDesc}
                onChange={onChange}
                required
                />
          </div>

          <hr />
          <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Product Pricing</label>
                <input id="customInput" className='form-control form-group-input' type='number' placeholder='Enter Product Stock' name='productPrice' value={formData.productPrice} onChange={onChange} required/>
            </div>

          <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Product In Stock</label>
                <input id="customInput" className='form-control form-group-input' type='number' placeholder='Enter Product Stock' name='productStock' value={formData.productStock} onChange={onChange} required/>
            </div>
           
            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Discount Price</label>
                <input id="customInput" className='form-control form-group-input' type='number' placeholder='Enter Discounted' name='discountPrice' value={formData.discountPrice} onChange={onChange} required/>
            </div>

            <hr />

            <div className='form-group form-group-style'>
                <label id="labelInput" className='form-group-label'>Select Category</label>
                <select id="customInput" className='form-select form-group-input' name='category' defaultValue={formData.category} onChange={onChange} required>
                    <option value="">Choose..</option>
                    <option value="blood">Blood</option>
                    <option value="aids">Medical Aids</option>
                    <option value="equipments">Equipments</option>
                </select>
            </div>

            
           
            <div className='form-group font-weight-bold button-float'>
                <button className='btn btn-muted-blue'>
                    Edit Product
                </button>
            </div>

          </form>
        ) : (
          'Product Not Found'
        )}
              </div>
            </div>
        </div>
  
        
      </div>
    );
  };
export default ProductDetail