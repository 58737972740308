import React from 'react'
import { image_four, image_one, image_three, image_two } from '../assets'

const Hero = () => {
  return (
    <div className='hero'>

        <div className='hero-title'>
           <h3>Giving the Gift of Life to Others</h3> 
           <p>BloodBankNg acts as a centralized banking service for blood to patients in need within Nigeria.</p>
        </div>

        <div className='hero-img'>
          <img src={image_one} className='img-fluid' />
          <img src={image_two} className='img-fluid' />
          <img src={image_three} className='img-fluid' />
          <img src={image_four} className='img-fluid' />
        </div>
    </div>
    )
}

export default Hero