import { faCartShopping, faHandsHelping, faMoneyBill, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LineChart, Line, BarChart, Bar, PieChart, Pie, Tooltip, Legend, XAxis, YAxis, Cell } from 'recharts'
import Transaction from "../Transaction"
import { Link } from "react-router-dom"

export const AdminDashboard = ({orders, users, volunteers, products}) => {
  const pendingOrders = orders.filter((order) => order.status === "processing")
  const clients = users.filter((user) => user.userType === "user")
  const closedOrders = orders.filter((order) => order.status === 'closed');
  const totalRevenue = closedOrders.reduce((total, order) => total + order.total, 0);
  return (
    <div>
    <div className="admin__cards">
      <Link to="/orders" className="card my-2">
        <div className="card-body admin__card">
          <FontAwesomeIcon icon={faCartShopping} className="admin__card__icon" />
          <div className="admin__card__label">
              <p>Processing Orders</p>
             <p>{pendingOrders.length.toLocaleString()}</p>
          </div>
          
        </div>
      </Link>

    
      <Link to="/users" className="card my-2">
      <div className="card-body admin__card">
          <FontAwesomeIcon icon={faUsers} className="admin__card__icon" />
          <div className="admin__card__label">
            <p>Total Clients</p>
             <p>{clients.length.toLocaleString()}</p>
          </div>
          
        </div>
      </Link>
        
      <Link to="/orders" className="card my-2">
      <div className="card-body admin__card">
          <FontAwesomeIcon icon={faMoneyBill} className="admin__card__icon" />
          <div className="admin__card__label">
            <p>Total Revenue </p>
             <p>{totalRevenue.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}</p>
          </div>
          
        </div>
      </Link>
        
      <Link to="/volunteers" className="card my-2">
      <div className="card-body admin__card">
          <FontAwesomeIcon icon={faHandsHelping} className="admin__card__icon" />
          <div className="admin__card__label">
            <p>Volunteers</p>
             <p>{volunteers.length}</p>
          </div>
          
        </div>
      </Link>    
    </div>
    <div className="admin__content">
      <div className="stats_page">
         <StatsGraph orders={orders}  className="stats_graph"/>
      <Transaction orders={orders} products={products}  />
      </div>
      <div className={{ flex: 1 }}>
        <DoughnutOrders  users={users}/>
      </div>
     
    </div>
    </div>
  )
}

export const StatsGraph = ({orders}) => {
  const processingOrders = orders.filter((order) => order.status === "processing")
  const inTransitOrders = orders.filter((order) => order.status === "in-transit")
  const deliveredOrders = orders.filter((order) => order.status === "delivered")
  const declinedOrders = orders.filter((order) => order.status === "declined")
  const closedOrders = orders.filter((order) => order.status === "closed")
  const barChartData = [
    { name: 'Processing', Orders: processingOrders.length,  color: '#FF5733' },
    { name: 'In Transit', Orders:inTransitOrders.length,  color: '#FF5733' },
    { name: 'Delivered', Orders: deliveredOrders.length,  color: '#FF5733' },
    { name: 'Declined', Orders:declinedOrders.length,  color: '#FF5733' },
    { name: 'Closed', Orders:closedOrders.length, color:"#33FF57" },
    // Add more data as needed
  ];

  return( 
    <div className="chart_stats">
    <h6>Orders</h6>
    <BarChart width={900} height={390} data={barChartData}>
      <Bar dataKey="Orders" fill="#8884d8" />
      <XAxis dataKey="name" type="category" fill="color" />
      <YAxis type="number" />
    
      <Tooltip />
      <Legend />
      
    </BarChart>
  </div>
)
}

export const TotalOrders = () => {
  return (
    <div>
        Total Orders
    </div>
  )
}

export const DoughnutOrders = ({users}) => {
  const primaryUser = users.filter((user) => user.activate === "primary")
  const secondaryUser = users.filter((user) => user.activate === "secondary")
  const adminUser = users.filter((user) => user.userType === "admin")
  const data = [
    { name: 'User A', value: primaryUser.length },
    { name: 'User B', value: secondaryUser.length },
    { name: 'Admin', value: adminUser.length },
    // Add more data as needed
  ];
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];
  
  
  return(
    <div className="chart_stats">
    <h6>Users</h6>
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        cx={200}
        cy={200}
        outerRadius={80}
        fill="#8884d8"
        label
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  </div>
  )
}