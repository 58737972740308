import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllCarts, removeItemFromCart, reset, updateCartItem } from '../app/reducers/cartSlice';
import { blood_pint } from '../assets';

const CartItem = ({ item, products }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(item.quantity);
  const product = products.find((p) => p._id === item.productId);

  const handleQuantityChange = (newQuantity) => {
    if (newQuantity >= 1 && newQuantity <= product.productStock) {
      setQuantity(newQuantity);
      dispatch(updateCartItem({productId: item.productId, quantity:newQuantity}));
       dispatch(reset())
    }
  };

  const handleRemove = () => {
    dispatch(removeItemFromCart(item.productId));
    dispatch(getAllCarts())
  };

  
  

  return (
    <tr className='cart_table'>
      <td className='d-flex cart_table'>
        <>
          {product && product.productImage ? (
            <img
              src={`http://localhost:5000/uploads/${product.productImage}`}
              className="cart-img"
              alt=""
            />
          ) : (
            <img
              src={blood_pint}
              className="cart-img"
              alt=""
            />
          )}
        </>
        <div className='text-secondary px-4 cart-item-center'>
          <p className='cart-productName'>{product && product.productName || 'Loading...'}</p>
          <p>#{item.productId}</p>
          <p>{product && product.productDesc || 'Loading...'}</p>
        </div>
      </td>
      <td className='cart-price'>
        {product && product.discountPrice.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
      </td>
      <td>
          <>
          <button className='btn btn-light' onClick={() => handleQuantityChange(quantity + 1)}>+</button>
           <span>{quantity}</span>
         <button className='btn btn-light' onClick={() => handleQuantityChange(quantity - 1)}>-</button>
          </>
          {product &&
          
            <p className='text-danger text-sm text-center'>{quantity >= product.productStock ?
              <span className='text-italic cart-quantity-count'>Only {product && product.productStock} items avaliable</span>
            : null}</p>
          }
         
        
      </td>
      <td>
        <a className='cart-remove' onClick={handleRemove}>X</a>
      </td>
    </tr>
  );
};

export default CartItem;
