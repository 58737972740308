import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { deleteProduct, enlistProduct, getProducts } from '../app/reducers/productSlice'
import { Link } from 'react-router-dom'
import { addProductToCart } from '../app/reducers/cartSlice'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faMinusSquare, faPlusSquare, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'

const ProductListings = ({product, hasCart, isSuccess}) => {
// const {deleteProduct} = useSelector((state) => state.product.deleteProduct)
   

 const dispatch = useDispatch()

 const enlistDelist = (product) => {
    dispatch(enlistProduct(product._id))
    product.enList ? alert(`Product ${product.productName} Enlisted`) : alert(`Product ${product.productName} Delisted`)
 }

  return (
    <tr className='align-items-center'>
    <td>
    <div>
      {product.productImage && (
      <img
        src={`/uploads/${product.productImage}`} // Adjust the path accordingly
        alt={product.productImage}
        className='img-pattern'
      />
    )}
    </div>
        
    </td>
    <td>
      {product.productName}
    </td>
    <td>
      {product.productPrice.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
    </td>
    <td>
      {product.discountPrice.toLocaleString('en-NG', { style: 'currency', currency: 'NGN' })}
    </td>
    <td>
      {product.productStock.toLocaleString()}
    </td>

    <td>
      {product.category} - {product.enList ? "Enlisted": "Delisted"}
    </td>

    
    <td>
      {format(new Date(product.createdAt), 'MM/dd/yyyy')}
    </td>
    <td className='actions'>
    <Link to={`/products/${product._id}`} className='btn btn-outline-primary'>
          <FontAwesomeIcon icon={faEdit} />
    </Link>
       <button className="btn btn-danger" onClick={() => enlistDelist(product)}>
          {product.enList ? 
            <FontAwesomeIcon icon={faPlusSquare}  /> 
           : 
            <FontAwesomeIcon icon={faMinusSquare} /> 
           }
       </button>
    </td>

        {/* <div className='card'>
        <div className='card-header'>
              <h3>{product.productName}</h3>
              <Link to={`/products/${product._id}`}>
                Edit
              </Link>
              <button className="btn btn-danger" onClick={() => dispatch(deleteProduct(product._id))}>X</button>
        </div>
        <div className='card-body'>
            <p>
                {product.productDesc}
            </p>

            {hasCart ?(
              <button onClick={() => dispatch(addProductToCart(product._id))} className='btn btn-dark'>
                Add to Cart
              </button>

            ): null}
        </div>
           
        </div> */}
    </tr>
  )
}

export default ProductListings