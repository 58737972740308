import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../app/reducers/productSlice';
import { addProductToCart, getAllCarts, reset } from '../app/reducers/cartSlice';
import { blood_pint } from '../assets';
import { useEffect } from 'react';

const Listings = ({ product,carts }) => {
  const dispatch = useDispatch();

  const allItems = carts.flatMap((cart) => cart.items || [])
  const isProductInCart = allItems.some((item) => item.productId === product._id);
  const cartItem = product && allItems.find((item) => item.productId === product._id);
  const buttonText = isProductInCart ? `In Cart (${cartItem.quantity})` : 'Add To Cart';

  
  useEffect(() => {
    // console.log(product)
    dispatch(getProducts())
    dispatch(getAllCarts())
    dispatch(reset())
  }, [dispatch,product._id])
  return (
    <div className="listing align-items-center">
      {product.productImage ? (
        <img
          src={`http://localhost:5000/uploads/${product.productImage}`}
          className="object-center object-fit-contain listing-img w-full"
          alt=""
        />
      ) : (
        <img
          src={blood_pint}
          className="object-center object-fit-contain listing-img w-full"
          alt=""
        />
      )}

      <h2 className={``}>{product.productName}</h2>
      <p>{product.productDesc}</p>
      <span className="listing-price">
        <div>
          <span className='strike' style={{ textDecoration:"line-through" }}>N {product.productPrice.toLocaleString()}</span>
          <h1 className="text-crimson strikethrough">N{product.discountPrice.toLocaleString()}</h1>
        </div>
        
       
        <div className="d-flex justify-content-between">
          {product.category === 'blood' ? (
            <p>
              per <span className="fw-bold fs-5">pint</span>
            </p>
          ) : null}
        </div>
      </span>
     
        <button
          className="mt-5 btn w-full btn-primary py-3"
          onClick={() => dispatch(addProductToCart(product._id))}
        >
        {buttonText}
        </button>
    
    </div>
  );
};

export default Listings;
