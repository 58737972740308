import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileService from "./profileService";

// const user = JSON.parse(localStorage.getItem('user'))

const initialState = {
    profiles: [],
    profile:  null,
    isError: false, 
    isSuccess: false,
    isLoading: false, 
    message: null
}

/**  Create */
export const createProfile = createAsyncThunk('profile/create', async(profile, thunkAPI)=>{
    try {
        const token = thunkAPI.getState().auth.user.token
        return await profileService.createProfile(profile, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const fetchProfile = createAsyncThunk('profile/all', async(_, thunkAPI) => {
    try {
        const userId = thunkAPI.getState().auth.user._id;
        const token = thunkAPI.getState().auth.user.token;
        const userProfile = await profileService.getUserProfile(userId, token);
        return userProfile;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
    }
})

export const getProfiles = createAsyncThunk("profile/getAll", async(_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        const profiles = await profileService.getProfiles(token)
        return profiles
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const profileSlice = createSlice({
    name:'profile',
    initialState,
    reducers:{  
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.message = ''
            state.isError = false
        }
    },

    extraReducers:(builder) => {
        builder
        .addCase(createProfile.pending, (state) =>{
            state.isLoading = true
        })
        .addCase(createProfile.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.profile = action.payload
            state.message = ''
        })
        .addCase(createProfile.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(fetchProfile.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchProfile.fulfilled, (state, action) => {
            state.isLoading = false;
            state.profile = action.payload;
          })
          .addCase(fetchProfile.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.payload;
          });
          
    }


})

export const {reset} = profileSlice.actions
export default profileSlice.reducer