import React, { useEffect } from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import ProductForm from '../components/ProductForm'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../app/reducers/authSlice'

const CreateProduct = () => {

  const {user} = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if(!user){
      navigate('/login')
    }

    if(user.userType !== "admin")
    {
      navigate("/dashboard")
    }
  }, [])

  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
  }
  
  return (
    <div className='dashboard'>
        <Sidebar type={user.userType}/>
     <div className='content'>
        <Navbar onLogout={onLogout} type={user.userType}/>
        <div className='contain'>
        <div className='contain-header'>
                <div>
                   <h3 className='contain-title'>Create Product</h3>
                   <p className='text-sm text-secondary'>
                    Create New Products to be displayed to customers 
                   </p>
                </div>
               
                <Link to="/products" className='btn btn-lg btn-muted-blue'>
                    Product
                </Link>
            </div>

            <ProductForm />
        </div>
     </div>
    </div>
  )
}

export default CreateProduct