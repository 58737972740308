import { faFacebookSquare, faLinkedin, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { faICursor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  const socialMedia = {
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  }

  const hrefs={
    color:"#fff",
    backgroundColor:"#fff"
  }

  const icons = {
    fontSize:"30px",
    margin:"0px 4px"
  }


  return (
    <div className='p-5 footer-flex'>
      <div className='footer-info'>
      <div className='nav-icon'>
            BloodBankNg
        </div>
        <p>
          Building easy and secured ways to workflow BloodBankNg.
        </p>
        <span>
          We are there for u
        </span>

        <span style={socialMedia}>
       
          <a href='https://www.linkedin.com/company/bloodbank-ng/about/?viewAsMember=true' target='_blank'  rel='noopener noreferrer' >
            <FontAwesomeIcon icon={faLinkedin} style={icons} />
          </a>
          <a href='https://twitter.com/Bloodbankng' target='_blank'  rel='noopener noreferrer' >
            <FontAwesomeIcon icon={faTwitterSquare} style={icons} />
          </a>
        </span>
      </div>
      
      
    </div>
  )
}

export default Footer