import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getProducts, reset } from '../app/reducers/productSlice';
import {reset as cartReset} from '../app/reducers/cartSlice';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../components/Navbar';
import Listings from '../components/Listings';
import ReactPaginate from 'react-paginate';
import { getAllCarts } from '../app/reducers/cartSlice';
import { logout } from '../app/reducers/authSlice';

const Shop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { products, loading } = useSelector((state) => state.products);
  const { carts } = useSelector((state) => state.carts);

  const { user } = useSelector((state) => state.auth);

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;

  const enlistedProducts = products.filter(product => product.enList);


  useEffect(() => {
    if (!user) {
      navigate('/login');
      
    } 

    if(user.userType !== "user")
    {
      navigate("/dashboard")
    }
      dispatch(getProducts());
      dispatch(getAllCarts())
      dispatch(reset());
      dispatch(cartReset());
  
  }, [navigate, dispatch, user]);
  if (loading) {
    // You can render a loading spinner or placeholder while products are being fetched
    return <div>Loading...</div>;
  }

  // Filter products based on search query
  const filteredProducts = enlistedProducts.filter((product) =>
    product.productName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the index range for the current page
  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = currentPage * itemsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const onLogout = () => {
    dispatch(logout())
    navigate('/login'); // Redirect to the login page

}


  return (
    <div className='dashboard'>
      <Sidebar type={user.userType} />
      <div className='content'>
      {
        user.userType !== null && 
        <Navbar onLogout={onLogout} type={user.userType}/>

      }
        <div className='contain'>
          <div className='contain-header'>
            <h4>Sales</h4>
            <div className='search-container w-full px-5'>
              <input
                type='text'
                className='form-control w-100'
                placeholder='Search by product name'
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <Link to="/carts" className='btn btn-dark visit-shop'>
              <span>
                Visit Cart
                <FontAwesomeIcon icon={faShoppingCart} />
              </span>
            </Link>
          </div>

          <div className='listings'>
            {currentProducts.map((product, i) => (
              <Listings key={product._id} product={product} carts={carts} />
            ))}
          </div>

          <ReactPaginate
            pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
            onPageChange={handlePageClick}
            containerClassName='pagination'
            activeClassName='active'
            previousClassName='page-item'
            nextClassName='page-item'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            pageClassName='page-item'
            pageLinkClassName='page-link'
          />
        </div>
      </div>
    </div>
  );
};

export default Shop;
