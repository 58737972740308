import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faDashboard,  faHandHolding,  faHandsHelping, faListAlt,  faShoppingCart, faUserCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Sidebar = ({type}) => {
  return (
    <div className='sidebar'>
        <div className='sidebar-top'>
            BloodBankNg 
        </div>

        <div className='sidebar-list'>
            {type === "admin"  &&  <ul>
                <li>
                    <Link to="/dashboard" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faDashboard} />
                    </Link>
                </li>
                <li>
                    <Link to="/list-orders" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faListAlt} />
                    </Link>
                </li>
              
                <li>
                    <Link to="/products" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faBox} />
                    </Link>
                </li>
                <li>
                    <Link to="/users" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faUsers} />
                    </Link>
                </li>
                
                <li>
                    <Link to="/volunteers" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faHandsHelping} />
                    </Link>
                </li>
                <li>
                    <Link to="/tickets" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faHandHolding} />
                    </Link>
                </li>
                {/* <li><p>w</p>Users</li> */}
            </ul>}
            {type === "user" &&  <ul>
                <li>
                    <Link to="/dashboard" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faDashboard} />
                    </Link>
                </li>
                
                
                <li>
                    <Link to="/list-user-orders" className='sidebar-listing'>
                        <FontAwesomeIcon icon={faListAlt} /
                        >
                    </Link>
                </li>
                <li>
                    <Link to="/shop" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faShoppingCart} />
                    </Link>
                </li>
               
                <li>
                    <Link to="/carts" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faShoppingCart} />
                    </Link>
                </li>
              
                <li>
                    <Link to="/profile" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faUserCircle} />
                    </Link>
                </li>

                <li>
                    <Link to="/tickets" className='sidebar-listing'>
                            <FontAwesomeIcon icon={faHandHolding} />
                    </Link>
                </li>
           
            </ul> }
           
        </div>
    </div>
  )
}

export default Sidebar