import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/profile/';

const createProfile = async (profile, token) => {
  const formData = new FormData();

  // Append product data
  Object.keys(profile).forEach((key) => {
    // Check if the property is bsnImage and handle it appropriately
    if (key === 'bsnImage') {
      formData.append('bsnImage', profile.bsnImage, profile.bsnImage.name);
    } else {
      formData.append(key, profile[key]);
    }
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios.post(API_URI, formData, config);

    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const getUserProfile = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URI + userId, config);

  return response.data;
};

const profileService = {
  createProfile,
  getUserProfile,
};

export default profileService;
