import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const VolunteerModal = ({  volunteer, onClose }) => {

useEffect(() => {
  console.log('VolunteerModal useEffect triggered');
}, []);

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton className='bg-crimson text-white'>
        <Modal.Title>Volunteer Info</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Last Name: {volunteer.lastName}</p>
        <p>First Name: {volunteer.firstName}</p>
        <p>Email Address: {volunteer.email}</p>
        <p>Age : {volunteer.age} Years</p>
        <p>Blood Group: {volunteer.bloodGroup}</p>
        <p>Date  Recorded: {volunteer.createdAt}</p>
        <p>Ailment: {volunteer.ailment}</p>
        <p>Height: {volunteer.height}</p>
        {/* Add more details as needed */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose} className='text-white'>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VolunteerModal;
