import React from 'react'

const Stats = () => {
  return (
    <div className='stats'>
        <div className='stats-info'>
            More than 25 Camps in <span>Abuja & Lagos</span>
        </div>
        <div className='stats-listings'>
            <ul>
                <li>
                    <p>10+</p>
                    <p>Blood Banks</p>
                </li>
                <li>
                    <p>500+</p>
                    <p>Testing Kits</p>
                </li>
                <li>
                    <p>2500+</p>
                    <p>Volunteers</p>
                </li>
            </ul>
        </div>
    </div>
  )
}

export default Stats