import React from 'react'
// import Banner from '../components/Banner'
import Nav from '../components/Nav'
import Hero from '../components/Hero'
import Stats from '../components/Stats'
import About from '../components/About'
import Partners from '../components/Partners'
import ServiceOffering from '../components/ServiceOffering'
import Footer from '../components/Footer'

const Home = () => {
  return (
    <div className='home'>
      <div className='section pb-5'>
          <Nav />
          <Hero />
      
          <Stats />
          <About />
          <Partners />
          <ServiceOffering />
        </div>

      <div className='footer'>
        <Footer />
      </div>
     
    </div>
  )
}

export default Home