import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { allVolunteers, reset } from '../app/reducers/volunteerSlice';
import { useDispatch } from 'react-redux';
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2';
const MySwal = withReactContent(Swal);

const EditVolunteerModal = ({  volunteer, onClose, handleSave, isSuccess }) => {
    const dispatch = useDispatch()
    const [editedVolunteer, setEditedVolunteer] = useState({
        firstName: volunteer.firstName || '', // Initialize with default values
        lastName: volunteer.lastName || '',
        email: volunteer.email || '',
        bloodGroup: volunteer.bloodGroup || '',
        bloodType: volunteer.bloodType || '',
        ailment: volunteer.ailment || '',
        height: volunteer.height || '',
        weight:volunteer.weight || "",
      });
    

    const handleInputChange = (e) => {
        setEditedVolunteer({
          ...editedVolunteer,
          [e.target.name]: e.target.value,
        });
      };

    const handleSaveChanges = () => {
        // Pass the updated user data to the parent component
        handleSave(editedVolunteer);
        if (isSuccess) {
          MySwal.fire({
            icon: 'success',
            title: `Volunteer Data updated`,
          });
        }
        onClose();
        dispatch(reset())
        dispatch(allVolunteers())
      };
  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton className='bg-crimson text-white'>
        <Modal.Title>Volunteer Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form>
        <div className='form-group my-2'>
            <label>FirstName</label>
            <input className='form-control mt-1' type='text' name="firstName" value={editedVolunteer.firstName} onChange={handleInputChange} required/>
        </div>
        <div className='form-group my-2'>
            <label>LastName</label>
            <input className='form-control mt-1' type='text' name="lastName"  value={editedVolunteer.lastName} onChange={handleInputChange} required/>
        </div>
        <div className='form-group my-2'>
            <label>Blood Group</label>
            <select className='form-control mt-1' name='bloodGroup' value={editedVolunteer.bloodGroup} onChange={handleInputChange} required>
            <option value="" disabled>Select Blood Group</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
        </select>
        </div>

        <div className='form-group my-2'>
            <label>Blood Type</label>
            <select className='form-control mt-1' name="bloodType" value={editedVolunteer.bloodType} onChange={handleInputChange} required>
            <option value="" disabled>Select Blood Type</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="AB">AB</option>
            <option value="O">O</option>
        </select>
        </div>
        <div className='form-group my-2'>
            <label>Height (in feets)</label>
            <input className='form-control mt-1' type='number' name="height" value={editedVolunteer.height} onChange={handleInputChange} required/>
        </div>

        <div className='form-group my-2'>
            <label>Weight (in kg)</label>
            <input className='form-control mt-1' type='number' name="weight" value={editedVolunteer.weight} onChange={handleInputChange} required/>
        </div>

        <div className='form-group my-2'>
            <label>Allergic reaction</label>
            <textarea className='form-control mt-2' row="10" cols={"2"} name="ailment"  value={editedVolunteer.ailment} onChange={handleInputChange} required>

            </textarea>
        </div>
      </form>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="secondary" onClick={onClose} className='text-white'>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges} className='text-white'>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EditVolunteerModal