import axios from 'axios';
const API_URI = 'https://api.centobloodbank.com/api/products/';

const createProduct = async (product, token) => {
  const formData = new FormData();

  // Append product data
  Object.keys(product).forEach((key) => {
    formData.append(key, product[key]);
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data', // Set the content type for file uploads
    },
  };

  try {
    const response = await axios.post(API_URI, formData, config);
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const fetchProductById = async (productId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(`${API_URI}/${productId}`, config);
  return response.data;
};

const updateProductById = async ({ productId, updatedProduct }, token) => {
  const formData = new FormData();

  // const API_URI = `${API_URI}/${productId}`;

  // Append product data to formData
  Object.keys(updatedProduct).forEach((key) => {
    formData.append(key, updatedProduct[key]);
  });

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios.put(
      `${API_URI}/${productId}`,
      formData,
      config,
    );
    return response.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

const getProducts = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URI, config);

  return response.data;
};

// Delete user goal
const deleteProduct = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URI + id, config);

  return response.data;
};

const enlistProduct = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.put(`${API_URI}/enlist/${id}`, null, config);

    return response.data;
  } catch (error) {
    throw error.message;
  }
};

const productService = {
  createProduct,
  getProducts,
  deleteProduct,
  enlistProduct,
  fetchProductById,
  updateProductById,
};

export default productService;
