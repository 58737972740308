import { faEdit, faReceipt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import PreviewComplaintModal from './PreviewComplaintModal'
import StateComplaintModal from './StateComplaintModal'

const ComplaintListing = ({complaint, user, isSuccess}) => {
    const [previewComplaintModal, setPreviewComplaintModal] = useState(false);
    const [changeComplaintModal, setChangeComplaintModal] = useState(false);

    const previewModal = () => {
        setPreviewComplaintModal(true);
      }
    
      const handleClosePreview = () => {
        setPreviewComplaintModal(false);
      }
    
      const complaintModal = () => {
        setChangeComplaintModal(true);
      }
    
      const handleCloseComplaint = () => {
        setChangeComplaintModal(false);
      }

  return (
    <>
        <tr className='text_spacing'>
            <td></td>
            <td>{complaint.orderId ? complaint.orderId : "NIL"}</td>
            <td>{complaint.type}</td>
            <td>{complaint.desc}</td>
            <td>{complaint.status}</td>
            <td>{complaint.createdAt}</td>
            <td>{complaint.updatedAt}</td>
            <td className='d-flex align-content-center justify-content-around'>
                <button className='btn btn-muted-blue' onClick={previewModal}> 
                    <FontAwesomeIcon icon={faReceipt} />
                </button>
                {
                  user && user.userType === "admin" ? 
                   <button className='btn btn-danger text-white' onClick={complaintModal}>
                    <FontAwesomeIcon icon={faEdit} /> 
                </button> : null
                }
               
            </td>
        </tr>

    {previewComplaintModal && (
        <PreviewComplaintModal show={previewComplaintModal} handleClose={handleClosePreview} complaint={complaint} />
    )}

    {changeComplaintModal && 
        (
        <StateComplaintModal show={changeComplaintModal} handleClose={handleCloseComplaint} complaint={complaint} isSuccess={isSuccess} />
        )
    }
    </>
  )
}

export default ComplaintListing