import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { allUsers, reset } from '../app/reducers/userSlice';
import UserListings from '../components/UserListings';
import ReactPaginate from 'react-paginate';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { logout } from '../app/reducers/authSlice';

const Users = () => {
  const {user}  = useSelector((state) => state.auth)
  const { users, isLoading, isSuccess } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);


  const filteredUsers = users && users.filter((user) =>
    `${user.firstName} ${user.lastName} ${user.email} ${user.userType}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = currentPage * itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  useEffect(() => {
    if(!user){
      navigate('/login')
    }
    if(user.userType !== "admin")
    {
      navigate("/dashboard")
    }
    dispatch(allUsers())
    dispatch(reset())
  }, [dispatch, navigate, user])

  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
  }

  return (
    <div className='dashboard'>
      <Sidebar type={user.userType} />
      <div className='content'>
        <Navbar onLogout={onLogout} type={"user"}/>
        <div className='contain'>
          <div className='contain-header'>
            <h3 className='contain-title'>Users ({users.length})</h3>
            <Link to="/shop" className='btn btn-muted-blue'>
              View Sales
            </Link>
          </div>
          {isLoading && <p>Loading...</p>}
          <div className='search-container w-60 my-4'>
            <input
              type='text'
              className='form-control'
              placeholder='Search by name, email, or user type'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
      <div className='table-container'>
       <table className='table table-bordered'>
        <thead className='table-secondary text-white-50'>
                <tr className='bg-primary'>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>User Type</th>
                  <th>Activate</th>
                  {/* <th>Date</th> */}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user) => (
                  <UserListings key={user._id} user={user} isSuccess={isSuccess} />
                ))}
              </tbody>
            </table>

            <ReactPaginate
              pageCount={Math.ceil(filteredUsers.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="page-item"
              nextClassName="page-item"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              pageClassName="page-item"
              pageLinkClassName="page-link"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
